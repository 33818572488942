import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from '../helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode,LineClearLogoImg} from '../helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import saveAs from 'file-saver';
import {Button, Input, Confirm,Table,Form,Grid,Segment,Label,Radio,Divider,Modal,Loader} from 'semantic-ui-react';
import {ref, firebaseAuth,firebase} from '../../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';

var JsBarcode = require('jsbarcode');


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'20038',
      //要改這裡，要不然資料庫會弄錯 而且可以看畫面下面，確定是我的資料不是薇恩的
      // 'customerNumber':'test',
      'deliveryNation':'MY',
      'itemNumber':1,
      'itemName1':'包',
      'boxNumber':1,
      'SGContent':'',
      //這就是要把訂單編碼的前幾碼抓出來，讓客人好輸入
      'poPredefinedNumber':'',
      'today':todayTitle()[0],
      'year':todayTitle()[1],
      'month':todayTitle()[2],
      'day':todayTitle()[3],
      'userid':'',
      'receiver':'',
      'amount':0,
      'zipCode':'',
      'printStatus':false,
      'poNumber':'',
      'oldBoxNumber':'',
      'payment':'',
      'contactENG':'',
      
    };
    
    this.print=this.print.bind(this);
    this.changeDeliveryNation=this.changeDeliveryNation.bind(this);
    this.handleInputChange=this.handleInputChange.bind(this);
    this.addBox=this.addBox.bind(this);
    this.cutBox=this.cutBox.bind(this);
    this.addItem=this.addItem.bind(this);
    this.cutItem=this.cutItem.bind(this);
    this.SGaddItem=this.SGaddItem.bind(this);
    this.SGcutItem=this.SGcutItem.bind(this);
    this.print=this.print.bind(this);
    this.poNumberChange=this.poNumberChange.bind(this);
    this.handlePhoneNumberChange=this.handlePhoneNumberChange.bind(this);
    this.deleteOpen=this.deleteOpen.bind(this);
    this.deleteRecord=this.deleteRecord.bind(this);
    this.handleItemAmountChange=this.handleItemAmountChange.bind(this);
    this.rePrint=this.rePrint.bind(this);
    this.boxNumberChange=this.boxNumberChange.bind(this);
    
  }


componentDidMount (){
    var userid = firebaseAuth.currentUser.uid;
    //console.log(userid)
    this.setState({
      'userid':userid,
    })
    
    //因為國家一開始預設MY，所以一開機就把馬來西亞資料帶進來
    //後面userid的寫法和changeDeliveryNation不同是因為這裡讀不到this.state.userid
    /*薇恩說不要自帶
    ref.child('/infos/customers/'+this.state.customerNumber+'/ReceiverList/MY/'+userid).on('value', (snapshot)=> {
      if(snapshot.val()==null){
          
      }else{
        let receiver=snapshot.val();
        this.setState({
          'receiver':receiver
        })
        //console.log(this.state.receiver)
      }
    })*/

    ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+userid).on('value', (snapshot)=> {
          if(snapshot.val()){
                let queryResult=snapshot.val();
                //console.log(queryResult)
                var dataOnScreen=[]
                var keyList=[]
                Object.keys(queryResult).forEach((key)=> {
                    keyList.push(key)
                })
                keyList=keyList.reverse()
                keyList.forEach((key)=>{
                  var po=queryResult[key];
                  dataOnScreen.push(
                      <Table.Row style={{'height':44 }} >                            
                          <Table.Cell >
                              <Grid centered style={{'padding-top':'0.3em' }} onClick={()=>this.rechangePOContent(po.deliveryNumber)}>{po.deliveryNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell style={{width:250 }}>
                              <Grid centered style={{'padding-top':'0.3em'}}>{po.contact}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                                <Grid centered style={{'padding-top':'0.3em' }}><Button size='medium' color='green'  style={{'font-size':'12px'}} name={po.deliveryNumber} onClick={this.rePrint}>印製標籤</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>                               
                                <Grid centered style={{'padding-top':'0.3em' }}><Button content='刪除'  size='medium' color='red' name={po.deliveryNumber} onClick={(e)=>this.deleteOpen(po.總箱數,e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                          </Table.Cell>                     
                      </Table.Row>
                  )
                })

                
                this.setState({
                  dataOnScreen:dataOnScreen
                })
                
          }else(
                
                this.setState({
                  dataOnScreen:''
                })

          )
      
    })
   
    
  }


//決定現在是要寄香港還是馬來西亞
//因為恩碩現在只有馬來西亞，用不到
changeDeliveryNation(e,{ value }){
      //因為要提供快速地址輸入，所以我要先把各個國家的寄送資訊叫進來
      ref.child('/infos/customers/'+this.state.customerNumber+'/receiverList/'+value+'/'+this.state.userid).on('value', (snapshot)=> {
        if(snapshot.val()==null){
            
        }else{
        let receiver=snapshot.val();
        this.setState({
          'receiver':receiver
        })
        //console.log(this.state.receiver)
        }
      })
     
      this.setState({
        'deliveryNation':value,
      }) 
    
}

handlePhoneNumberChange(e){
  /*
  var phone=e.target.value;
  var obj=this.state.receiver
  //console.log(obj)
  
  if(obj.hasOwnProperty(phone)){
    //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
    var target=obj[phone]
    this.setState({ 
        'contact':target.name, 
        'address':target.address, 
    });
    
  }
  */
  
  this.setState({ 
    [e.target.name]: e.target.value,
   });
}

//這是所有欄位普用型的function
handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
}


boxNumberChange(e){
    if(this.state.deliveryNation==='SG'){
     //我讓第一次輸入可以一次決定箱數
     
     if(e.target.value>0){
          //只要數字更動，就要把每一箱的基礎第一件做出來，要不然後面object就會error
          let SGContent={}
          for(let i=0;i<e.target.value;i++){
            let number=i+1
            SGContent[number]={}
            //資料結構上，每一箱都會有自己的重量、金額和content
            SGContent[number]['content']={}
            //不存在的object不能建立，所以要分層建立
            SGContent[number]['content']['1']={
              name:'包',
              number:'',
              amount:''
            } 
              
          }
          let boxNumber=Number(e.target.value)
          
          this.setState({
            [e.target.name]:  boxNumber,
            SGContent:SGContent
          })
      }else{
        //這一格只要輸入的不是數字就給它空白，避免後面的問題
        this.setState({ [e.target.name]: '' })
      }


    }else{
        this.setState({ [e.target.name]: e.target.value })
    }

}

//增加一個箱子
addBox(){
  let number=this.state.boxNumber
  number=number+1
  let SGContent=this.state.SGContent
  SGContent[number]={}
  SGContent[number]['amount']=0
  SGContent[number]['content']={}
  SGContent[number]['content']['1']={
    name:'包',
    number:'',
    amount:''
  } 
  this.setState({
    boxNumber:number,
    SGContent:SGContent
  })
}

//減少一個箱子
cutBox(boxNumber){
  let number=this.state.boxNumber
  if(number>1){
    number=number-1
    let SGContent=this.state.SGContent
    delete SGContent[boxNumber]
    let keys=Object.keys(SGContent)
    let length=Object.keys(SGContent).length
    let newContent={}
    for(let i=0;i<length;i++){
        let number=i+1
        let key=keys[i]
        newContent[number]=SGContent[key]
    }

    this.setState({
      boxNumber:number,
      SGContent:newContent
    })

  }
  

}
//一般加一個品項 
addItem(){
    let number=this.state.itemNumber
    number=number+1
    this.setState({
      'itemNumber':number,
      ['itemName'+number]:'',
      ['itemNumber'+number]:'',
      ['itemAmount'+number]:''
    })
    

}

//一般少一個品項
cutItem(){
  var number=this.state.itemNumber
  if(number>1){
    delete this.state['itemName'+number]
    delete this.state['itemNumber'+number]
    delete this.state['itemAmount'+number]
    number=number-1
  }
  var totalAmount=0
  for(var i=0;i<number;i++){
    let key=i+1 
    totalAmount=Number(totalAmount)+Number(this.state['itemAmount'+key]) 
  }

  this.setState({
    'itemNumber':number,
    'amount':totalAmount,
  })
  
}

//SG每一箱加一個品項 
SGaddItem(boxNumber){
  //SG增加品項，要看增加在哪一箱
  let SGContent=this.state.SGContent
  let boxContent=SGContent[boxNumber]['content']
  let number=Object.keys(boxContent).length
  number=number+1
  boxContent[number]={
    name:'',
    number:'',
    amount:''
  }

  this.setState({
     SGContent:SGContent,
  })
  

}

//SG每一箱減一個品項
//刪除品項要記得重算每一箱的報關價值
SGcutItem(boxNumber){
  let SGContent=this.state.SGContent
  let boxContent=SGContent[boxNumber]['content']
  let number=Object.keys(boxContent).length
  if(number!==1){
    delete boxContent[number]
    let amount=0
    Object.keys(boxContent).forEach((key)=> {
        amount=amount+Number(boxContent[key]['amount'])
    })

    SGContent[boxNumber]['amount']=amount

    this.setState({
      SGContent:SGContent,
    })

  }
  

}

//修改每一箱的相關資料用這個，比如:weight
SGChangeBoxSpec(boxNumber,e){
  let SGContent=this.state.SGContent
  SGContent[boxNumber][e.target.name]=e.target.value
  this.setState({
    SGContent:SGContent,
  })

}

//改變每一箱的內容物用這個
SGChangeContent(boxNumber,itemNumber,e){
  let SGContent=this.state.SGContent
  SGContent[boxNumber]['content'][itemNumber][e.target.name]=e.target.value
  this.setState({
    SGContent:SGContent,
  })
}

//當各品項的報關金額改變的時候，就要更新content
//以及重新計算每一箱的報關金額
SGChangeBoxAmount(boxNumber,itemNumber,e){
  let SGContent=this.state.SGContent
  SGContent[boxNumber]['content'][itemNumber][e.target.name]=e.target.value
  let amount=0
  Object.keys(SGContent[boxNumber]['content']).forEach((key)=> {
      amount=amount+Number(SGContent[boxNumber]['content'][key]['amount'])
  })

  SGContent[boxNumber]['amount']=amount

  this.setState({
    SGContent:SGContent,
  })

}


//我們把所有的主要印製處理步驟寫在這個程式裡面，讓大家用。
//沒有特別要求就用這個
printProcess(currency,labelNumber,acNumber,deliveryNumber,amount){
        
  //快遞內容品項
  let content='';
  
  //下面這個是要獨立存起來，幫DEC算總數
  //順便把content的部分組裝起來
  let contentCalculateBase=[];
  for(let i=0;i<this.state.itemNumber;i++){
    let number=i+1
    let item=this.state['itemName'+number]+'*'+this.state['itemNumber'+number]+'  '
    content=content+item
    contentCalculateBase[this.state['itemName'+number]]={'Number':this.state['itemNumber'+number],'Amount':this.state['itemAmount'+number]}
  
  }
  
  
  var logo=LogoImg();
  var dataURL=textToBase64Barcode(deliveryNumber);

  // var address=''
  // if(this.state.deliveryNation==='JP'){
  //   address= this.state.doorNumber+' '+this.state.address
  // }else{
  //   address= this.state.address
  // }
   
  var address=this.state.address
  
  pdfmake.vfs = pdffonts.pdfMake.vfs;

  //你只要是印很多張，你就需要這個LabelArr，把他們排列一下
  var LabelArr = [];
  for(let i=0;i<labelNumber;i++){
    if(i===labelNumber-1){ 
      let Label=[
        { table: {
          widths: ['auto', '*', '*', '*', '*', '*'],
          //下面是要做一開始的logo、barcode、和國碼標
          body: [
            [
              {
              // you'll most often use dataURI images on the browser side
              // if no width/height/fit is provided, the original size will be used
              image:logo,
              width:100, 
              height:20,
              alignment: 'center',
              colSpan: 3},
              '',
              '',
              { rowSpan: 3,
                colSpan: 3,
                text: this.state.deliveryNation,
                style: 'head',  
                width:100, 
                alignment: 'center',          
              },
              '',
              ''
  
            ],
            [{ image:dataURL,
              colSpan: 3,
              width:140, 
              height:50,
              alignment: 'center',
            },
            {},
            {},
            {},
            {},
            {}
            ],
            [{text:deliveryNumber,
              colSpan: 3, 
              style: 'head2',
              width:140, 
              height:10,
              alignment: 'center', },
            {},
            {},
            {},
            {},
            {}
            ],
            //這是馬來西亞標籤特別要加的
            [{ text: 'A/C NO:'},
            {text: acNumber,
              colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{ text: 'PO:'},
            {text:deliveryNumber,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Shipper:'},
            {text:'ROBINMAY',colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Consignee:'},
            {text: this.state.contact,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'TEL:'},
            {text: this.state.receiverPhoneNumber,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Address:',rowSpan: 2},
            {text: address,rowSpan: 2,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{},
            {},
            {},
            {},
            {},
            {}
            ],
  
            // [
            // //   {text: 'Declared Value:'},
            // // {text: currency+amount,colSpan: 5},
            // {},
            // {},
            // {},
            // {},
            // {},
            // {}
            // ],
  
            [{text: 'Weight:'},
            //KGS前面後一格，看起來比較好看
            {text:this.state.weight+' KGS   -'+(i+1)+'/'+this.state.boxNumber+' Boxes',colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{text: 'Description:'},
            {text: content,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
          ]
        },
        },     
      ];
      LabelArr.push(Label);
    }else{
      let Label=[
        { table: {
          widths: ['auto', '*', '*', '*', '*', '*'],
          //下面是要做一開始的logo、barcode、和國碼標
          body: [
            [
              {
              // you'll most often use dataURI images on the browser side
              // if no width/height/fit is provided, the original size will be used
              image:logo,
              width:100, 
              height:20,
              alignment: 'center',
              colSpan: 3},
              '',
              '',
              { rowSpan: 3,
                colSpan: 3,
                text: this.state.deliveryNation,
                style: 'head',  
                width:100, 
                alignment: 'center',          
              },
              '',
              ''
  
            ],
            [{ image:dataURL,
              colSpan: 3,
              width:140, 
              height:50,
              alignment: 'center',
            },
            {},
            {},
            {},
            {},
            {}
            ],
            [{text:deliveryNumber,
              colSpan: 3, 
              style: 'head2',
              width:140, 
              height:10,
              alignment: 'center', },
            {},
            {},
            {},
            {},
            {}
            ],
            //這是馬來西亞標籤特別要加的
            [{ text: 'A/C NO:'},
            {text: acNumber,
              colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{ text: 'PO:'},
            {text:deliveryNumber,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Shipper:'},
            {text:'ROBINMAY',colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Consignee:'},
            {text: this.state.contact,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'TEL:'},
            {text: this.state.receiverPhoneNumber,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
            [{text: 'Address:',rowSpan: 2},
            {text: address,rowSpan: 2,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{},
            {},
            {},
            {},
            {},
            {}
            ],
  
            // [
            //   //   {text: 'Declared Value:'},
            //   // {text: currency+amount,colSpan: 5},
            //   {},
            //   {},
            // {},
            // {},
            // {},
            // {}
            // ],
  
            [{text: 'Weight:'},
            //KGS前面後一格，看起來比較好看
            {text:this.state.weight+' KGS   -'+(i+1)+'/'+this.state.boxNumber+' Boxes',colSpan: 5},
            {},
            {},
            {},
            {}
            ],
            [{text: 'Description:'},
            {text: content,colSpan: 5},
            {},
            {},
            {},
            {}
            ],
  
          ]
        },
        pageBreak: 'after'
       },     
      ];
      LabelArr.push(Label);
    }
  }


  pdfmake.fonts = {
  ch: {
  normal: 'kaiu.ttf',
  bold: 'kaiu.ttf',
  italics: 'kaiu.ttf',
  bolditalics: 'kaiu.ttf'
  }};

  var docDefinition = {
      content:LabelArr,
      defaultStyle: {
      font: 'ch',
      fontSize: 11
      } ,
      styles: {
        head: {    
          fontSize: 66,
          bold: true
        },
        head2: {               
          fontSize: 22,
          bold: true
        },

      },

      // a string or { width: number, height: number }
      // 下面是10*10的標籤設計
      pageSize: { width: 283, height: 425},
      

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 3, 3,3, 0 ],
    };
  
  var goodData=[];
  //快遞單號
  goodData.deliveryNumber=deliveryNumber;
  //訂單單號
  goodData.poNumber=deliveryNumber;
  goodData.寄件人姓名='恩碩有限公司(薇恩)';
  goodData.寄件地址=' ';
  //收件人姓名
  goodData.contact=this.state.contact;
  //因為日本是拼裝地所以改一下
  goodData.收件地址=address;
  //收件連絡電話
  goodData.receiverPhoneNumber=this.state.receiverPhoneNumber;
  goodData.內容物=content;
  goodData.總箱數=this.state.boxNumber;
  goodData.國別=this.state.deliveryNation;        
  goodData['總重量-公斤']=this.state.weight;
  goodData['報關總值']=this.state.amount;
  goodData.收件人郵遞區號=this.state.zipCode;
  goodData.itemNumber=this.state.itemNumber;
  goodData.amount=this.state.amount;
  goodData.time=Date();
  goodData.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});

  //日本的部分要多存兩個欄位
  if(this.state.deliveryNation==='JP'){  
    let name= this.state.contactENG
    name=name.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\ |\=|\||\\|\[|\]|\{|\}|\;|\:|\”|\’|\,|\<|\.|\>|\/|\?|\+]/g,' ');

    goodData.payment=this.state.payment
    goodData.contactENG=name
  }

  for(var i=0;i<this.state.itemNumber;i++){
    var number=i+1
    goodData['itemName'+number]=this.state['itemName'+number]
    goodData['itemNumber'+number]=this.state['itemNumber'+number]
    goodData['itemAmount'+number]=this.state['itemAmount'+number]
  }

  var receiver=[];
  //receiver.phone=this.state.receiverPhoneNumber;
  receiver.name=this.state.contact;
  receiver.address=this.state.address;

  //確定存檔後，要繼續做的事情
  
  return [docDefinition,goodData,receiver]

}

//我們在這裡準備標籤檔案、存檔資料、和寄送人資料
MYprintProcess(labelNumber){
        
  //快遞內容品項
  let content='';
  
  //下面這個是要獨立存起來，幫DEC算總數
  //順便把content的部分組裝起來
  let contentCalculateBase=[];
  for(let i=0;i<this.state.itemNumber;i++){
    let number=i+1
    let item=this.state['itemName'+number]+'*'+this.state['itemNumber'+number]+'  '
    content=content+item
    contentCalculateBase[this.state['itemName'+number]]={'Number':this.state['itemNumber'+number],'Amount':this.state['itemAmount'+number]}
  
  }
  
  
  let logo=LineClearLogoImg();
  let weight=(Number(this.state.weight)/Number(this.state.boxNumber)).toFixed(1)
  pdfmake.vfs = pdffonts.pdfMake.vfs;

  //你只要是印很多張，你就需要這個LabelArr，把他們排列一下
  var LabelArr = [];
  let barCodeInfo=labelNumber['WayBill']
  for(let i=0;i<this.state.boxNumber;i++){
    if(i===this.state.boxNumber-1){ 
      let Label= [ 
        { table: {
          widths: ['auto', '*', '*', '*'],
          //下面是要做一開始的logo、barcode、和國碼標
          body: [
            [
              {
              // you'll most often use dataURI images on the browser side
              // if no width/height/fit is provided, the original size will be used
              image:logo,
              width:70, 
              height:10,
              alignment: 'left',
              colSpan: 4,
              border: [false, false, false, true],},
              '',
              '',
              '',
              
  
            ],
            [
              
              {
                
                table: {
                  
                  body: [
                    [{ 
              
                      qr: barCodeInfo[i], 
                      fit: '80', 
                      alignment: 'center', 
                      margin: [0, 10, 0, 5],
                      colSpan:3,
                      border: [false, false, false, false],        
                    },
                    '',
                    ''],
                    [
                      { text:'',
                        border: [false, false, false, false]},
                      { text:'',
                      border: [false, false, false, false]},
                      { text:'',
                      border: [false, false, false, false]}
                    ],
                    [
                        { text: 'PCS',
                        fontSize: 6,
                        alignment: 'left',
                        bold: true,
                        border: [true, true, false, false],
                        },
                        { text: '',
                        
                        border: [false, true, false, false],
                        },
                        { text: 'M',
                        fontSize: 6,
                        alignment: 'right',
                        bold: true,
                        border: [false, true, true, false],
                        },


                    ],
                    [{  text: (i+1)+'/'+this.state.boxNumber,
                        colSpan:3,
                        fontSize: 18,
                        alignment: 'center',
                        bold: true,
                        border: [true, false, true, true],
                      },
                      '',
                      ''
                    ],
                    
                  ],
                 
                },
                border: [false, false, false, false],
                layout:{
                  hLineColor:'#999999',
                  vLineColor:'#999999',
                 

                },
                
                
                
               }, 
               {
                
                  table: {
                    
                    body: [
                      [{ image:textToBase64Barcode(barCodeInfo[i]),
                        colSpan: 3,
                        height:50,
                        width:170,
                        alignment: 'center',
                        border: [false, false, false, false],
                        margin: [ 0, 0, 0, -10 ]
                      },
                      '',
                      ''],
                      //空白格
                      [{text:barCodeInfo[i],
                        height:1,
                        border: [false, false, false, false],
                        colSpan: 3,
                        alignment: 'center',
                        fontSize: 10,
                       },'',''],
                      //第一排
                      [{text:'Origin Agent',
                        alignment: 'left',
                        bold: true,
                        fontSize: 6,
                        border: [true, true, true, false],
                      }, 
                      {text:'Origin Depot',
                      alignment: 'left',
                      bold: true,
                      fontSize: 6,
                      border: [true, true, true, false],
                      }, 
                      {text:'Origin Hub',
                      alignment: 'left',
                      bold: true,
                        fontSize: 6,
                        border: [true, true, true, false],
                      }],
                      //第二排(資料排)
                      [
                        { text:'',
                        alignment: 'center',
                        bold: true,
                        fontSize: 15,
                        border: [true, false, true, false],
                        }, 
                        { text:labelNumber['PickupBranchCode'],
                          alignment: 'center',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, false],
                        }, 
                        { text:labelNumber['PickupHubCode'],
                          alignment: 'center',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, false],
                        }
                      ],
                      //第三排
                      [
                        { text:'Dest. Hub',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }, 
                        { text:'Dest. Depot',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }, 
                        { text:'Dest. Agent',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }
                      ],
                      //第四排(資料排)
                      [
                        { text:labelNumber['DeliveryHubCode'],
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }, 
                        { text:labelNumber['DeliveryBranchCode'],
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }, 
                        { text:'',
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }
                      ]

                    ]
                  },
                  border: [false, false, false, false],
                  layout:{
                          hLineColor:'#999999',
                          vLineColor:'#999999',
                         

                  },
                  colSpan:3,
                  
                  
              },
               
              '',
              '',
            
            ],
            [
              { text:'From',
                alignment: 'left',
                bold: true,
                fontSize: 12,
                colSpan:2,
                border: [true, true, false, false],
              },
              '',
              { text:'Account No.: N6900038',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:2,
                border: [false, true, true, false],
              },
              ''

            ],
            [{  text:'FREEGO TRADING AND LOGISTICS',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                width:220,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'MR TIEN',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'60126368616',
                alignment: 'left',
                fontSize: 8,
                colSpan:2,
                border: [true, false, false, true],
             },
             '',
             {  text:'Cust. Ref. No.:',
                colSpan:2,
                fontSize: 8,
                bold: true,
                border: [false, false, true, true],
             },
             ''

            ],
            [
              { text:'To',
                alignment: 'left',
                bold: true,
                fontSize: 12,
                colSpan:2,
                border: [true, true, false, false],
              },
              '',
              { text:'',
                colSpan:2,
                border: [false, true, true, false],
              },
              ''

            ],
            [{  text:this.state.address,
                alignment: 'left',
                width:220,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:this.state.contact,
                alignment: 'left',
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:this.state.receiverPhoneNumber,
                alignment: 'left',
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, true],
             },
             '',
             '',
             ''

            ],
            [{  text:'Weight(kg):'+weight+'   |Pcs:'+  this.state.boxNumber  + '   |Type:Package',
                alignment: 'left',
                bold: true,
                fontSize: 6,
                colSpan:2,
                border: [true, true, false, true],
             },
             '',
             {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                alignment: 'right',
                colSpan:2,
                fontSize: 6,
                bold: true,
                border: [false, true, true, true],
             },
             '',
             

            ],
            [
              {text:'',
               colSpan:4,
               height:3,
               border: [false, true,false, true]},
              '',
              '',
              ''
            ],
            [
              {text:'Acknowledgement by Recipient',
               fontSize: 8,
               colSpan:4,
               height:3,
               bold: true,
               border: [true, true,true, true]},
              '',
              '',
              ''
            ],
            [
              {text:'',
               colSpan:2,
               border: [true, true,false, false]},
              '',
              {text:'Name :',
               fontSize: 8,
               colSpan:2,
               border: [false, true,true, false]},
              ''
            ],
            [
              {text:'Signature &  Company Stamp',
               alignment: 'center',
               color:'#b3b3b3',
               fontSize: 8,
               colSpan:2,
               border: [true, false,false, false]},
              '',
              {text:'Date & Time :',
               fontSize: 8,
               colSpan:2,
               border: [false, false,true, false]},
              ''
            ],
            [
              {text:'',
               colSpan:2,
               border: [true, false,false, true]},
              '',
              {text:'IC / Passport :',
               fontSize: 8,
               colSpan:2,
               border: [false, false,true, true]},
              ''
            ],

            
            [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
            fontSize: 6,
            margin: [0, 1, 0, 0],
            colSpan: 4,
            bold: true,
            border: [false, false, false, false],
            },
            '',
            '',
            ''
            ] 
          ],
          
         },
         }  
        ]
      LabelArr.push(Label);
    }else{
      let Label= [ 
        { table: {
          widths: ['auto', '*', '*', '*'],
          //下面是要做一開始的logo、barcode、和國碼標
          body: [
            [
              {
              // you'll most often use dataURI images on the browser side
              // if no width/height/fit is provided, the original size will be used
              image:logo,
              width:70, 
              height:10,
              alignment: 'left',
              colSpan: 4,
              border: [false, false, false, true],},
              '',
              '',
              '',
              
  
            ],
            [
              
              {
                
                table: {
                  
                  body: [
                    [{ 
              
                      qr: barCodeInfo[i], 
                      fit: '80', 
                      alignment: 'center', 
                      margin: [0, 10, 0, 5],
                      colSpan:3,
                      border: [false, false, false, false],        
                    },
                    '',
                    ''],
                    [
                      { text:'',
                        border: [false, false, false, false]},
                      { text:'',
                      border: [false, false, false, false]},
                      { text:'',
                      border: [false, false, false, false]}
                    ],
                    [
                        { text: 'PCS',
                        fontSize: 6,
                        alignment: 'left',
                        bold: true,
                        border: [true, true, false, false],
                        },
                        { text: '',
                        
                        border: [false, true, false, false],
                        },
                        { text: 'M',
                        fontSize: 6,
                        alignment: 'right',
                        bold: true,
                        border: [false, true, true, false],
                        },


                    ],
                    [{  text: (i+1)+'/'+this.state.boxNumber,
                        colSpan:3,
                        fontSize: 18,
                        alignment: 'center',
                        bold: true,
                        border: [true, false, true, true],
                      },
                      '',
                      ''
                    ],
                    
                  ],
                 
                },
                border: [false, false, false, false],
                layout:{
                  hLineColor:'#999999',
                  vLineColor:'#999999',
                 

                },
                
                
                
               }, 
               {
                
                  table: {
                    
                    body: [
                      [{ image:textToBase64Barcode(barCodeInfo[i],undefined,undefined,60),
                        colSpan: 3,
                        height:50,
                        width:170,
                        alignment: 'center',
                        border: [false, false, false, false],
                        margin: [ 0, 0, 0, -10 ]
                      },
                      '',
                      ''],
                      //空白格
                      [{text:barCodeInfo[i],
                        height:1,
                        border: [false, false, false, false],
                        colSpan: 3,
                        alignment: 'center',
                        fontSize: 10,
                       },'',''],
                      //第一排
                      [{text:'Origin Agent',
                        alignment: 'left',
                        bold: true,
                        fontSize: 6,
                        border: [true, true, true, false],
                      }, 
                      {text:'Origin Depot',
                      alignment: 'left',
                      bold: true,
                      fontSize: 6,
                      border: [true, true, true, false],
                      }, 
                      {text:'Origin Hub',
                      alignment: 'left',
                      bold: true,
                        fontSize: 6,
                        border: [true, true, true, false],
                      }],
                      //第二排(資料排)
                      [
                        { text:'',
                        alignment: 'center',
                        bold: true,
                        fontSize: 15,
                        border: [true, false, true, false],
                        }, 
                        { text:labelNumber['PickupBranchCode'],
                          alignment: 'center',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, false],
                        }, 
                        { text:labelNumber['PickupHubCode'],
                          alignment: 'center',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, false],
                        }
                      ],
                      //第三排
                      [
                        { text:'Dest. Hub',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }, 
                        { text:'Dest. Depot',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }, 
                        { text:'Dest. Agent',
                          alignment: 'left',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 6,
                          border: [true, true, true, false],
                        }
                      ],
                      //第四排(資料排)
                      [
                        { text:labelNumber['DeliveryHubCode'],
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }, 
                        { text:labelNumber['DeliveryBranchCode'],
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }, 
                        { text:'',
                          alignment: 'center',
                          fillColor: '#000000',
                          color:'#ffffff',
                          bold: true,
                          fontSize: 15,
                          border: [true, false, true, true],
                        }
                      ]

                    ]
                  },
                  border: [false, false, false, false],
                  layout:{
                          hLineColor:'#999999',
                          vLineColor:'#999999',
                         

                  },
                  colSpan:3,
                  
                  
              },
               
              '',
              '',
            
            ],
            [
              { text:'From',
                alignment: 'left',
                bold: true,
                fontSize: 12,
                colSpan:2,
                border: [true, true, false, false],
              },
              '',
              { text:'Account No.: N6900038',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:2,
                border: [false, true, true, false],
              },
              ''

            ],
            [{  text:'FREEGO TRADING AND LOGISTICS',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                width:220,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'MR TIEN',
                alignment: 'left',
                bold: true,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:'60126368616',
                alignment: 'left',
                fontSize: 8,
                colSpan:2,
                border: [true, false, false, true],
             },
             '',
             {  text:'Cust. Ref. No.:',
                colSpan:2,
                fontSize: 8,
                bold: true,
                border: [false, false, true, true],
             },
             ''

            ],
            [
              { text:'To',
                alignment: 'left',
                bold: true,
                fontSize: 12,
                colSpan:2,
                border: [true, true, false, false],
              },
              '',
              { text:'',
                colSpan:2,
                border: [false, true, true, false],
              },
              ''

            ],
            [{  text:this.state.address,
                alignment: 'left',
                width:220,
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:this.state.contact,
                alignment: 'left',
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, false],
             },
             '',
             '',
             ''

            ],
            [{  text:this.state.receiverPhoneNumber,
                alignment: 'left',
                fontSize: 8,
                colSpan:4,
                border: [true, false, true, true],
             },
             '',
             '',
             ''

            ],
            [{  text:'Weight(kg):'+weight+'   |Pcs:'+  this.state.boxNumber  + '   |Type:Package',
                alignment: 'left',
                bold: true,
                fontSize: 6,
                colSpan:2,
                border: [true, true, false, true],
             },
             '',
             {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                alignment: 'right',
                colSpan:2,
                fontSize: 6,
                bold: true,
                border: [false, true, true, true],
             },
             '',
             

            ],
            [
              {text:'',
               colSpan:4,
               height:3,
               border: [false, true,false, true]},
              '',
              '',
              ''
            ],
            [
              {text:'Acknowledgement by Recipient',
               fontSize: 8,
               colSpan:4,
               height:3,
               bold: true,
               border: [true, true,true, true]},
              '',
              '',
              ''
            ],
            [
              {text:'',
               colSpan:2,
               border: [true, true,false, false]},
              '',
              {text:'Name :',
               fontSize: 8,
               colSpan:2,
               border: [false, true,true, false]},
              ''
            ],
            [
              {text:'Signature &  Company Stamp',
               alignment: 'center',
               color:'#b3b3b3',
               fontSize: 8,
               colSpan:2,
               border: [true, false,false, false]},
              '',
              {text:'Date & Time :',
               fontSize: 8,
               colSpan:2,
               border: [false, false,true, false]},
              ''
            ],
            [
              {text:'',
               colSpan:2,
               border: [true, false,false, true]},
              '',
              {text:'IC / Passport :',
               fontSize: 8,
               colSpan:2,
               border: [false, false,true, true]},
              ''
            ],

            
            [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
            fontSize: 6,
            margin: [0, 1, 0, 0],
            colSpan: 4,
            bold: true,
            border: [false, false, false, false],
            },
            '',
            '',
            ''
            ] 
          ],
          
         },
         
         pageBreak: 'after'
        }  
        ]
      LabelArr.push(Label);
    }
  }


  pdfmake.fonts = {
  ch: {
  normal: 'kaiu.ttf',
  bold: 'kaiu.ttf',
  italics: 'kaiu.ttf',
  bolditalics: 'kaiu.ttf'
  }};

  var docDefinition = {
      content:LabelArr,
      defaultStyle: {
      font: 'ch',
      fontSize: 11
      } ,
      styles: {
        head: {
          
          fontSize: 66,
          bold: true
        },

      },

      // a string or { width: number, height: number }
      // 下面是10*10的標籤設計
      pageSize: { width: 283, height: 425},
      

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 3, 3,3, 0 ],
  };
  
  var goodData=[];
  //快遞單號 我抓主單號當快遞單號
  goodData.deliveryNumber=barCodeInfo[0];
  //把lineclear的相關回傳存起來
  goodData.MYdeliveryData=labelNumber;
  //訂單單號
  goodData.poNumber=barCodeInfo[0];
  goodData.寄件人姓名='恩碩有限公司(薇恩)';
  goodData.寄件地址=' ';
  //收件人姓名
  goodData.contact=this.state.contact;
  goodData.收件地址=this.state.address;
  //收件連絡電話
  goodData.receiverPhoneNumber=this.state.receiverPhoneNumber;
  goodData.內容物=content;
  goodData.總箱數=this.state.boxNumber;
  goodData.國別=this.state.deliveryNation;        
  goodData['總重量-公斤']=this.state.weight;
  goodData['報關總值']=this.state.amount;
  goodData.收件人郵遞區號=this.state.zipCode;
  goodData.itemNumber=this.state.itemNumber;
  goodData.amount=this.state.amount;
  goodData.time=Date();
  goodData.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});

  for(var i=0;i<this.state.itemNumber;i++){
    var number=i+1
    goodData['itemName'+number]=this.state['itemName'+number]
    goodData['itemNumber'+number]=this.state['itemNumber'+number]
    goodData['itemAmount'+number]=this.state['itemAmount'+number]
  }

  var receiver=[];
  //receiver.phone=this.state.receiverPhoneNumber;
  receiver.name=this.state.contact;
  receiver.address=this.state.address;

  //確定存檔後，要繼續做的事情
  
  return [docDefinition,goodData,receiver]

}

//因應SG需要分單的處理，特別做這個處理步驟
SGprintProcess(currency,deliveryNumber){
    let logo=LogoImg()  
    pdfmake.vfs = pdffonts.pdfMake.vfs
    //你只要是印很多張，你就需要這個LabelArr，把他們排列一下
    let LabelArr = [];
    //把一單化多單，準備存進prepareToShip
    let prepareToShipData=[];


    Object.keys( this.state.SGContent).forEach((key)=> {
      //印製標籤分頁的問題，所以要分兩種狀況
      //最後一箱，或是唯一一箱，調整單號，不要pagebreak，輸出
      //其他箱要調整單號，要加pagebreak，輸出
      
      if(key===String(this.state.boxNumber)){

        var unitDeliveryNumber=''
        
        let boxNumber=key
        let Space=2-boxNumber.length 
        for (let i = 0; i < Space; i++) {
          boxNumber ='0' + boxNumber;
        }
        //這裡的這裡的deliveryNumber是基本款，沒有帶01
        unitDeliveryNumber=deliveryNumber+boxNumber
        
        
        let dataURL=textToBase64Barcode(unitDeliveryNumber)
        let target=this.state.SGContent[key]
        let amount=Number((target['amount']/22).toFixed(1))

        let content='';
        prepareToShipData[unitDeliveryNumber]={}
        let thisPO=prepareToShipData[unitDeliveryNumber]
        let unitNumber=0
        Object.keys( target['content']).forEach((key)=> {
          let item=target['content'][key]['name']+'*'+target['content'][key]['number']+'  '
          content=content+item

          unitNumber=unitNumber+1
          thisPO['itemName'+unitNumber]=target['content'][key]['name']
          thisPO['itemNumber'+unitNumber]=target['content'][key]['number']
          thisPO['itemAmount'+unitNumber]=target['content'][key]['amount']
        })

        let Label=[
          { table: {
            widths: ['auto', '*', '*', '*', '*', '*'],
            //下面是要做一開始的logo、barcode、和國碼標
            body: [
              [
                {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image:logo,
                width:100, 
                height:20,
                alignment: 'center',
                colSpan: 3},
                '',
                '',
                { rowSpan: 3,
                  colSpan: 3,
                  text: this.state.deliveryNation,
                  style: 'head',  
                  width:100, 
                  alignment: 'center',          
                },
                '',
                ''

              ],
              [{ image:dataURL,
                colSpan: 3,
                width:140, 
                height:50,
                alignment: 'center',
              },
              {},
              {},
              {},
              {},
              {}
              ],
              [{text:deliveryNumber,
                colSpan: 3, 
                style: 'head2',
                width:140, 
                height:10,
                alignment: 'center', },
              {},
              {},
              {},
              {},
              {}
              ],
              //SG這一格是設成zipcode
              [{ text: 'A/C NO:'},
              {text: this.state.zipCode,
                colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{ text: 'PO:'},
              {text:deliveryNumber,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Shipper:'},
              {text:'ROBINMAY',colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Consignee:'},
              {text: this.state.contact,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'TEL:'},
              {text: this.state.receiverPhoneNumber,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Address:',rowSpan: 2},
              {text: this.state.address,rowSpan: 2,colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{},
              {},
              {},
              {},
              {},
              {}
              ],

              // [
              // //   {text: 'Declared Value:'},
              // // {text: currency+amount,colSpan: 5},
              // {},
              // {},
              // {},
              // {},
              // {},
              // {}
              // ],

              [{text: 'Weight:'},
              //KGS前面後一格，看起來比較好看
              {text:target['weight']+' KGS   -'+key+'/'+this.state.boxNumber+'Boxes',colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{text: 'Description:'},
              {text: content,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

            ]
          },
          },     
        ];
        LabelArr.push(Label);
        
        //準備prepareToShip的資料
        thisPO.deliveryNumber=unitDeliveryNumber;
        //訂單單號
        thisPO.poNumber=deliveryNumber;
        thisPO.寄件人姓名='恩碩有限公司(薇恩)';
        thisPO.寄件地址=' ';
        //收件人姓名
        thisPO.contact=this.state.contact;
        thisPO.收件地址=this.state.address;
        //收件連絡電話
        thisPO.receiverPhoneNumber=this.state.receiverPhoneNumber;
        thisPO.內容物=content;
        thisPO.總箱數=this.state.boxNumber;
        thisPO.國別=this.state.deliveryNation;        
        thisPO['總重量-公斤']=target['weight'];
        thisPO['報關總值']=target['amount'];
        thisPO.收件人郵遞區號=this.state.zipCode;
        thisPO.itemNumber=Object.keys(target['content']).length;
        thisPO.amount=target['amount'];
        thisPO.time=Date();
        thisPO.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});

     
      }else{
        let boxNumber=key
        let Space=2-boxNumber.length 
        for (let i = 0; i < Space; i++) {
          boxNumber ='0' + boxNumber;
        }
        //這裡的這裡的deliveryNumber是基本款，沒有帶01
        let unitDeliveryNumber=deliveryNumber+boxNumber
        let dataURL=textToBase64Barcode(unitDeliveryNumber)
        let target=this.state.SGContent[key]
        let amount=Number((target['amount']/22).toFixed(1))

        let content='';
        prepareToShipData[unitDeliveryNumber]={}
        let thisPO=prepareToShipData[unitDeliveryNumber]
        let unitNumber=0
        Object.keys( target['content']).forEach((key)=> {
          let item=target['content'][key]['name']+'*'+target['content'][key]['number']+'  '
          content=content+item

          unitNumber=unitNumber+1
          thisPO['itemName'+unitNumber]=target['content'][key]['name']
          thisPO['itemNumber'+unitNumber]=target['content'][key]['number']
          thisPO['itemAmount'+unitNumber]=target['content'][key]['amount']

        })

        let Label=[
          { table: {
            widths: ['auto', '*', '*', '*', '*', '*'],
            //下面是要做一開始的logo、barcode、和國碼標
            body: [
              [
                {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image:logo,
                width:100, 
                height:20,
                alignment: 'center',
                colSpan: 3},
                '',
                '',
                { rowSpan: 3,
                  colSpan: 3,
                  text: this.state.deliveryNation,
                  style: 'head',  
                  width:100, 
                  alignment: 'center',          
                },
                '',
                ''

              ],
              [{ image:dataURL,
                colSpan: 3,
                width:140, 
                height:50,
                alignment: 'center',
              },
              {},
              {},
              {},
              {},
              {}
              ],
              [{text:deliveryNumber,
                colSpan: 3, 
                style: 'head2',
                width:140, 
                height:10,
                alignment: 'center', },
              {},
              {},
              {},
              {},
              {}
              ],
              //SG這一格是設成zipcode
              [{ text: 'A/C NO:'},
              {text: this.state.zipCode,
                colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{ text: 'PO:'},
              {text:deliveryNumber,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Shipper:'},
              {text:'ROBINMAY',colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Consignee:'},
              {text: this.state.contact,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'TEL:'},
              {text: this.state.receiverPhoneNumber,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Address:',rowSpan: 2},
              {text: this.state.address,rowSpan: 2,colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{},
              {},
              {},
              {},
              {},
              {}
              ],

              // [
              // //   {text: 'Declared Value:'},
              // // {text: currency+amount,colSpan: 5},
              // {},
              // {},
              // {},
              // {},
              // {},
              // {}
              // ],

              [{text: 'Weight:'},
              //KGS前面後一格，看起來比較好看
              {text:target['weight']+' KGS   -'+key+'/'+this.state.boxNumber+'Boxes',colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{text: 'Description:'},
              {text: content,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

            ]
          },
          pageBreak: 'after'
        },     
        ];
        LabelArr.push(Label);

        //準備prepareToShip的資料
        thisPO.deliveryNumber=unitDeliveryNumber;
        //訂單單號
        thisPO.poNumber=deliveryNumber;
        thisPO.寄件人姓名='恩碩有限公司(薇恩)';
        thisPO.寄件地址=' ';
        //收件人姓名
        thisPO.contact=this.state.contact;
        thisPO.收件地址=this.state.address;
        //收件連絡電話
        thisPO.receiverPhoneNumber=this.state.receiverPhoneNumber;
        thisPO.內容物=content;
        thisPO.總箱數=this.state.boxNumber;
        thisPO.國別=this.state.deliveryNation;        
        thisPO['總重量-公斤']=target['weight'];
        thisPO['報關總值']=target['amount'];
        thisPO.收件人郵遞區號=this.state.zipCode;
        thisPO.itemNumber=Object.keys(target['content']).length;
        thisPO.amount=target['amount'];
        thisPO.time=Date();
        thisPO.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});

      }
    })


    pdfmake.fonts = {
    ch: {
    normal: 'kaiu.ttf',
    bold: 'kaiu.ttf',
    italics: 'kaiu.ttf',
    bolditalics: 'kaiu.ttf'
    }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },
          head2: {
                
            fontSize: 16,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 425},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };


    //準備最後要存到orderInfo的資料
    let goodData=[];
    //SG的快遞單號一律用第一張訂單當單號
    goodData.deliveryNumber=deliveryNumber+'01';
    //訂單單號
    goodData.poNumber=deliveryNumber;
    goodData.寄件人姓名='恩碩有限公司(薇恩)';
    goodData.寄件地址=' ';
    //收件人姓名
    goodData.contact=this.state.contact;
    goodData.收件地址=this.state.address;
    //收件連絡電話
    goodData.receiverPhoneNumber=this.state.receiverPhoneNumber;
    goodData.內容物=this.state.SGContent;
    goodData.總箱數=this.state.boxNumber;
    goodData.國別=this.state.deliveryNation;        
    goodData['總重量-公斤']='';
    goodData['報關總值']='';
    goodData.收件人郵遞區號=this.state.zipCode;
    goodData.itemNumber='';
    goodData.amount='';
    goodData.time=Date();
    goodData.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});


    var receiver=[];
    //receiver.phone=this.state.receiverPhoneNumber;
    receiver.name=this.state.contact;
    receiver.address=this.state.address;
    //回傳資料
    return [docDefinition,goodData,receiver,prepareToShipData]

}

//啟動印製品項動作，這裡主要做分類動作，實際印製方式寫在前面的printProcess裡面
print(){

  var currency='';
  var labelNumber='';
  var acNumber='';
  var deliveryNumber='';
  var amount='';
  
  //先讓畫面暫停
  this.setState({
    loading:true,
  })
  //process是每個國家整理完資料，呼叫的第一個function
  //因為不同國家的原始資料結構不同，所以這裡要先個別處理一下資料
  //每個國家的個別處理方式都寫在外面了，譬如:this.SGprintProcess
  const process=()=>{
          //印標的檔案  
          let docDefinition=''

          let goodData=''
          let receiver=''
          let prepareToShipData=''
          if(this.state.deliveryNation==='MY'){
            //傳統版本
            // let result=this.printProcess(currency,labelNumber,acNumber,deliveryNumber,amount)
            // docDefinition=result[0]
            // goodData=result[1]
            // receiver=result[2]
            
            //API版本
            let result=this.MYprintProcess(labelNumber)
            docDefinition=result[0]
            goodData=result[1]
            receiver=result[2]
            deliveryNumber=goodData['deliveryNumber']
          }else if(this.state.deliveryNation==='SG'){
            //注意，送進SGprintProcess是基本款派發的deliveryNumber，沒有01
            let result=this.SGprintProcess(currency,deliveryNumber)
            //把SG第一張訂單的碼+01
            //這個號碼就是前台看到的號碼
            //為了配合大家都用deliveryNumber，所以SG只好在這裡做+01的動作，但是後面都要小心
            deliveryNumber=deliveryNumber+'01'
            docDefinition=result[0]
            goodData=result[1]
            receiver=result[2]
            prepareToShipData=result[3]
          }else if(this.state.deliveryNation==='JP'){
            //傳統版本
            let result=this.printProcess(currency,labelNumber,acNumber,deliveryNumber,amount)
            docDefinition=result[0]
            goodData=result[1]
            receiver=result[2]

          }

          //等一下第一次存檔成功以後，就要呼叫doTheRest
          const doTheRest=()=>{
                //把寄件人資料存起來，後續要查找
                //要存檔之前要先確認一下路徑上面的參數是不是空白，如果是空白，會讓整個node被刪除
                //不要用undefined的確認方式，因為這個file都會define，但是空值還是有問題
                if(this.state.deliveryNation!== '' & this.state.receiverPhoneNumber!== ''){
                  ref.child('/infos/customers/'+this.state.customerNumber+'/ReceiverList/'+this.state.deliveryNation+'/'+this.state.userid+'/'+this.state.receiverPhoneNumber).set(receiver)
                }


                //為了配合新加坡訂單需要分單，所以現在有兩層資料夾。OrderInfo-->PrepareToShip
                //前面資料沒有問題以後，到這裡就要把資料準備好進PrepareToShip，等著給DEC抓
                if(this.state.deliveryNation==='SG'){
                    
                    //先判斷這個作業是改單還是新建單
                    //如果oldBoxNumber不為空，那就代表這是改單的作業
                    if(this.state.oldBoxNumber!==''){
                      //如果是改資料，重印製標籤，要先刪PrepareToShip資料，再印製              
                      for(let i=0;i<Number(this.state.oldBoxNumber);i++){
                        let boxNumber=i+1
                        //每一個箱號進來，分三種狀況
                        //第一箱，不改單號，直接刪掉
                        //最後一箱，刪完檔案，還要把新資料寫進去
                        //其他箱，改好單號，就可以刪掉
                        if(boxNumber===Number(this.state.oldBoxNumber)){
                            let unitDeliveryNumber=''
                            //注意，因為只有一箱，所以第一箱就是最後一箱
                            if(boxNumber===1){
                              unitDeliveryNumber=deliveryNumber
                            }else{
                              let Space=2-String(boxNumber).length 
                              for (let i = 0; i < Space; i++) {
                                boxNumber ='0' + boxNumber;
                              }
                              //要先拿到基本單號，改了尾碼後，才可以做下一步
                              unitDeliveryNumber=deliveryNumber.slice(0,13)+boxNumber
                            }
                            // 刪完檔案，再把新資料倒回去
                            ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+unitDeliveryNumber).set({}).then(() => {
                                    Object.keys(prepareToShipData).forEach((key)=> {
                                      ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+key).set(prepareToShipData[key]).then((error)=> {
                                        if(error){   
                                            this.setState({
                                              printErrorMessage:'訂單存檔沒有成功，再按一次!',
                                            })                 
                                        }          
                                      })
                                    })

                            })
                        
                        }else if(boxNumber===1){
                
                          ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+deliveryNumber).set({})
              
                        }else{
                            let Space=2-String(boxNumber).length 
                            for (let i = 0; i < Space; i++) {
                              boxNumber ='0' + boxNumber;
                            }
                            //要先拿到基本單號，改了尾碼後，才可以做下一步
                            let unitDeliveryNumber=deliveryNumber.slice(0,13)+boxNumber
                            ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+unitDeliveryNumber).set({})
                        }
                      }

                      

                    }else{
                        //SG新訂單就是直接轉進PrepareToShip就對了
                        Object.keys(prepareToShipData).forEach((key)=> {
                          ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+key).set(prepareToShipData[key]).then((error)=> {
                            if(error){   
                                this.setState({
                                  printErrorMessage:'訂單存檔沒有成功，再按一次!',
                                })                 
                            }          
                          })
                        })
                    }

                    //把品項內容恢復原狀
                    this.setState({ 
                      receiverPhoneNumber:'',
                      contact: '',   
                      address:'',
                      zipCode:'',
                      poNumber:'',
                      oldBoxNumber:'',
                      boxNumber:1,
                      weight:'',
                      amount:0,
                      itemNumber:1,
                      itemName1:'包',
                      itemNumber1:'',
                      itemAmount1:'',
                      SGContent:'',
                      printErrorMessage:'',
                      deleteRecordMessage:'',
                      loading:false,
                    });
                    
                    
                }else{
                    //MY的訂單直接轉進PrepareToShip就對了
                    ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+deliveryNumber).set(goodData).then((error)=> {
                      if(error){   
                          this.setState({
                            printErrorMessage:'訂單存檔沒有成功，再按一次!',
                          })                 
                      }          
                    })

                    //因為客人要準備下一張訂單，這裡我們要還原，把品項縮回1
                    for(var i=1; i<this.state.itemNumber;i++){
                      var number=i+1
                      delete this.state['itemName'+number]
                      delete this.state['itemNumber'+number]
                      delete this.state['itemAmount'+number]
                    }

                    //把品項內容恢復原狀
                    this.setState({ 
                      receiverPhoneNumber:'',
                      contact: '',
                      address:'',
                      zipCode:'',
                      poNumber:'',
                      boxNumber:1,
                      weight:'',
                      amount:0,
                      itemNumber:1,
                      itemName1:'包',
                      itemNumber1:'',
                      itemAmount1:'',
                      printErrorMessage:'',
                      deleteRecordMessage:'',
                      loading:false,
                      doorNumber:'',
                      contactENG:'',
                      payment:''
                    }); 
              
                }

                //告訴DEC進單了
                tellAdmin(this.state.customerNumber,this.state.deliveryNation,this.state.year,this.state.month,this.state.day);

                //準備出單印製
                pdfmake.createPdf(docDefinition).print();
            
    
          }
           //現在是三層處理結構preparetoship和orderinfo和一般資料夾
           //先存檔案一次
          ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+deliveryNumber).set(goodData).then((error)=> {
            if(error){   
                this.setState({
                  printErrorMessage:'訂單存檔沒有成功，再按一次!',
                })                 
            }          
          })      
          
          // ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+deliveryNumber).once('value', (snapshot)=> {
          //   //因為這邊是用on，只要檔案變化，就會印，所以要分三種狀況。
          //   //如果是因為delete造成檔案變化，這裡我們就不要動作
          //   if(snapshot.val()==null && this.state.deleteRecord===''){
          //       this.setState({
          //         printErrorMessage:'訂單儲存沒有成功，請再按一次!',
          //       })
          //   }else if(snapshot.val()==null && this.state.deleteRecord!==''){
                
          //   }else{    
          //           doTheRest()
          //   }

          // })
          

          //為了避免檔案沒存到就印標籤，所以先查一次，如果有，就印標
          var testRef = ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/testNumber')  
          testRef.transaction((currentData)=> {
                  // 這邊我就做一個交易測試
                  if(currentData===null){
                    return {'order':0}
                  }else{
                    currentData['order']=Number(currentData['order'])+1
                    return currentData; // 把單號+1，然後存回去
                  }
                },(error, committed, snapshot)=>{
                  if (error) {
                      this.setState({ 
                        printErrorMessage:'請檢查網路狀態，再列印一次!', 
                      })
                  } else if (committed) {
                    // 如果交易測試成功，我就把剩下的事情做完
                      doTheRest()
                  } 
                  
                  

                }
          )
          
          
    
  }

  //如果單號存在，我才要讓系統存
  //!this.state.poNumber||
  //||!this.state.weight
  
  if(this.state.deliveryNation==='HK'){
        if(this.state.amount===0||!this.state.weight){
          this.setState({ 
            printErrorMessage:'重量或是金額是不是忘記填了?',
          })
      
        }else{
          currency='HKD'
          labelNumber=Number(this.state.boxNumber)+1
          deliveryNumber='HK'+this.state.poNumber
          amount=Number((this.state.amount/4).toFixed(1))
          process()
        }
  }else if(this.state.deliveryNation==='MY'){
        if(this.state.amount===0||!this.state.weight){
                  this.setState({ 
                    printErrorMessage:'重量或是金額是不是忘記填了?',
                  })
      
        }else{   
                  //傳統版本
                    // currency='MYR'
                    // labelNumber=Number(this.state.boxNumber)
                    // acNumber='N6900038'
                    // amount=Number((this.state.amount/7.2).toFixed(1))
                    // if(this.state.poNumber){
                    //   deliveryNumber=this.state.poNumber
                    //   process()
                    // }else{
                    //       //現在關鍵就是這個，恩碩馬來西亞的這個欄位是系統派發的
                    //       //我要讓每一天的單號會從0001開始跑，所以我設計了一下，每天都會開一個新的訂單連號紀錄夾
                    //       //如果找不到這個紀錄夾，就會做一個到資料庫，這個設計很重要，要不然後面找不到
                          
                    //       //跑transaction的時候，那個node會被鎖住，你都不能動，所以如果有跑transaction，要注意前後文，有沒有動到同一個node，會被reject，不給動
                    //       //transaction的關鍵就是，第一個null的驗證不可以省，因為transaction一開始跑就是default null值，如果你不寫這句，那程式就會每一次都把null+1丟回去
                    //       //造成你以為資料庫不會動，其實他動過了，就是每次重跑null+1丟回去。
                    //       //那如果你會判斷null,他就會一直try到有資料進來，那就會跑else的部分，就會把值+1丟回去
                    //       //獲得的currentdata就是一個dictionary，所以就可以用key呼叫你要的欄位
                    //       // transaction就是調整值的過程，要進一步處理，要放在外面
                    //       var MYRef = ref.child('/infos/customers/'+this.state.customerNumber+'/MYDeliveryNumberData/'+this.state.year+'/'+this.state.month+'/'+this.state.day)  
                    //       MYRef.transaction((currentData)=> {
          
                    //               if(currentData===null){
                    //                 return {'order':0}
                    //               }else{
                    //                 currentData['order']=Number(currentData['order'])+1
                    //                 return currentData; // 把單號+1，然後存回去
                    //               }
                    //             },(error, committed, snapshot)=>{
                    //               if (error) {
                    //                   this.setState({ 
                    //                     printErrorMessage:'請再列印一次!', 
                    //                   })
                    //               } else if (committed) {
                    //                   //console.log(snapshot.val())
                    //                   var currentData=snapshot.val()
                    //                   deliveryNumber=(Number(currentData['order'])+1).toString()              
                    //                   var Space=4-deliveryNumber.length 
                    //                   for (var i = 0; i < Space; i++) {
                    //                     deliveryNumber ='0' + deliveryNumber;
                    //                   }
                    //                   var year=todayTitle()[1].toString().slice(2)
                    //                   deliveryNumber='MYRA'+year+todayTitle()[2]+todayTitle()[3]+deliveryNumber.toString()
                                      
                    //                   process()
                    //               } 
                                  
                                  
          
                    //             }
                    //       )
                    // }

                  //lineclear版本 
                  if(this.state.poNumber){
                        //如果單號存在，代表是要改單，所以我要刪除原本資料，讓訂單重新上傳重印
                        let target=this.state.poNumber
                        ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+target).set({})
                        ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).set({})
                        
                  }

                  
                  //準備API上傳資料
                  const infoUpload = {
                    "Shipment": []
                  }

                  var weight=(Number(this.state.weight)/Number(this.state.boxNumber)).toFixed(1)
                  let wayBill = [];
                  for (let j=0; j<this.state.boxNumber; j++) {
                      wayBill.push(
                        {
                          "Weight": weight,
                          "VolumeWidth": "1",                                                         
                          "VolumeHeight": "1",
                          "VolumeLength": "1"
          
                        }
                      )
                  }

                  let serviceType="ST00000087"
                  // 下面我們要先判斷郵遞區號是不是西馬的？ 如果是還要分成超過2公斤跟2公斤以下。
                  // if (Number(this.state.zipCode)<87000){
                  //   if(this.state.weight<2){
                  //     serviceType="ST00000108"
                  //   }else{
                  //     serviceType="ST00000087"
                  //   }
                  // }else{
                  //   // 東馬一律是087
                  //   serviceType="ST00000087"
                  // }

                  let info={
                    "ShipmentServiceType": "Standard Delivery",
                    "ConfigServiceCode":serviceType,
                    "SenderName": "MR TIEN",
                    "RecipientName": this.state.contact,
                    "ShipmentAddressFrom":  {
                      "CompanyName": "FREEGO TRADING AND LOGISTICS",
                      // 不能不填，不然會找不到地址
                      "UnitNumber": "null",
                      "Address": "No 3144, Jalan RJ1/1",
                      "Address2": "Taman Rasah Jaya",
                      "PostalCode": "70300",
                      "City": "Seremban",
                      "State": "Negeri Sembilan",
                      "Email": "",
                      "PhoneNumber": "60126368616",
                      "ICNumber": ""
                    },
                    "ShipmentAddressTo":   {
                        "CompanyName": "",
                        "UnitNumber": "null",
                        "Address": this.state.address,
                        "Address2": "",
                        "PostalCode": this.state.zipCode,
                        "City": "",
                        "State": "",
                        "Email": "",
                        "PhoneNumber": this.state.receiverPhoneNumber,
                        "ICNumber": ""
                    },
                    "RecipientPhone": this.state.receiverPhoneNumber,
                    "ParcelType": "Package",
                    "ShipmentRef": "",
                    "ShipmentDescription": null,
                    "ShipmentType": "Lodge In",
                    "PickUpAddressType":"Default",     //這是為了讓計價可以正確，所以最新要加入的
                    "CODAmount": "",
                    "WayBill": wayBill,
                    "DONumber": ""
                  }
                  //API資料準備完畢
                  infoUpload['Shipment'].push(info)

                  //開始上傳  這裡是可以做切換
                  //var bookingLineClear = firebase.functions().httpsCallable('bookingLineClearTest');
                  var bookingLineClear = firebase.functions().httpsCallable('bookingLineClear');

                  bookingLineClear({ data: infoUpload}).then((result) => {
                            var responseInfo=result['data']
                            let labelInfo=responseInfo['ResponseData']
                            //目前的設計是會讓整包data傳回來，那要自行判斷有沒有成功，有就可以印標籤
                            if(responseInfo['Status']===true){ 
                                //把資料存到labelNumber裡面
                                labelNumber=labelInfo[0]
                                process()
                            }else{
                              //沒成功，就要把訊息印製出來
                              console.log(responseInfo['Message'])
                              console.log(responseInfo['Reason'])
                              this.setState({
                                printErrorMessage : responseInfo['Reason']+'    請截圖通知跨境通',
                                loading:false
                              })
                            }
                            
                  })


          
        }

  }else if(this.state.deliveryNation==='SG'){
        currency='SGD$'
        let checkPoint=0
        Object.keys(this.state.SGContent).forEach((key)=> {
              if (this.state.SGContent[key]['amount']===0||!this.state.SGContent[key]['weight']){
                  checkPoint=1
              }

              Object.keys(this.state.SGContent[key]['content']).forEach((unit)=> {
                    if (this.state.SGContent[key]['content'][unit]['number']===0||this.state.SGContent[key]['content'][unit]['amount']===0){
                      checkPoint=1
                    }

              })

        })

        if(checkPoint!==0){
          this.setState({ 
            printErrorMessage:'重量或是金額是不是忘記填了?',
            loading:false,
          })
      
        }else{
           if(this.state.poNumber!==''){
              deliveryNumber=this.state.poNumber
              process()

           }else{
               
              var SGRef = ref.child('/infos/customers/'+this.state.customerNumber+'/SGDeliveryNumberData/'+this.state.year+'/'+this.state.month+'/'+this.state.day)  
              SGRef.transaction((currentData)=> {
          
                      if(currentData===null){
                          return {'order':0}
                      }else{
                        currentData['order']=Number(currentData['order'])+1
                        return currentData; // 把單號+1，然後存回去
                      }
                    },(error, committed, snapshot)=>{
                      if (error) {
                          this.setState({ 
                            printErrorMessage:'請再列印一次!', 
                          })
                      } else if (committed) {
                          //console.log(snapshot.val())
                          let currentData=snapshot.val()
                          //下面設定SG主單的單號，SGRM_210130_099
                          //後續如果多箱，需要繼續分單，SGRM_210130_099_01
                          //099的部分是下面的deliveryNumber
                          //拿到的currentData['order']是紀錄上的最後一張訂單
                          deliveryNumber=(Number(currentData['order'])+1).toString()   
                          //因為deliveryNumber預留三位，所以要看看夠不夠，不夠補位     
                          let Space=3-deliveryNumber.length 
                          for (let i = 0; i < Space; i++) {
                            deliveryNumber ='0' + deliveryNumber;
                          }
                          let year=todayTitle()[1].toString().slice(2)
                          deliveryNumber='SGRM'+year+todayTitle()[2]+todayTitle()[3]+deliveryNumber.toString()
                          
                          process()
                      } 
                      
                      
          
                    }
              )
           }
        }
     
  }else if(this.state.deliveryNation==='MO'){
        if(this.state.amount===0||!this.state.weight){
          this.setState({ 
            printErrorMessage:'重量或是金額是不是忘記填了?',
            loading:false,
          })
      
        }else{
          currency='NTD$'
          labelNumber=Number(this.state.boxNumber)+2
          deliveryNumber='MO'+this.state.poNumber
          amount=Number((this.state.amount/1).toFixed(1))
          process()
        }
  }else{
        //這一個部分，我們要處理日本的部分
        //先擺else裡面
        if(this.state.amount===0||!this.state.weight){
              this.setState({ 
                printErrorMessage:'重量或是金額是不是忘記填了?',
                loading:false,
              })
      
        }else{
              //傳統版本
              currency='NTD$'
              labelNumber=Number(this.state.boxNumber)
              amount=Number((this.state.amount/1).toFixed(1))
              if(this.state.poNumber){
                deliveryNumber=this.state.poNumber
                process()
              }else{
                    //現在關鍵就是這個，恩碩馬來西亞的這個欄位是系統派發的
                    //我要讓每一天的單號會從0001開始跑，所以我設計了一下，每天都會開一個新的訂單連號紀錄夾
                    //如果找不到這個紀錄夾，就會做一個到資料庫，這個設計很重要，要不然後面找不到
                    
                    //跑transaction的時候，那個node會被鎖住，你都不能動，所以如果有跑transaction，要注意前後文，有沒有動到同一個node，會被reject，不給動
                    //transaction的關鍵就是，第一個null的驗證不可以省，因為transaction一開始跑就是default null值，如果你不寫這句，那程式就會每一次都把null+1丟回去
                    //造成你以為資料庫不會動，其實他動過了，就是每次重跑null+1丟回去。
                    //那如果你會判斷null,他就會一直try到有資料進來，那就會跑else的部分，就會把值+1丟回去
                    //獲得的currentdata就是一個dictionary，所以就可以用key呼叫你要的欄位
                    // transaction就是調整值的過程，要進一步處理，要放在外面

                    // var JPRef = ref.child('/infos/customers/JPDeliveryNumberData') 
                    var JPRef = ref.child('/infos/customers/JPDeliveryNumberData/')  
                    
                    JPRef.transaction((currentData)=> {
    
                            if(currentData===null){
                              return {}
                            }else{
                              
                              deliveryNumber=Object.keys(currentData)[0]
                              delete currentData[deliveryNumber];
                              return currentData; // 把單號+1，然後存回去
                            }
                          },(error, committed, snapshot)=>{
                            if (error) {
                                this.setState({ 
                                  printErrorMessage:'請再列印一次!', 
                                })
                            } else if (committed) {
                                //console.log(snapshot.val())
                                process()
                            } 
                            
                            
    
                          }
                    )
                    
                    //這是原本薇恩最早的版本
                    // var JPRef = ref.child('/infos/customers/'+this.state.customerNumber+'/JPDeliveryNumberData/'+this.state.year+'/'+this.state.month+'/'+this.state.day)  
                    // JPRef.transaction((currentData)=> {
    
                    //         if(currentData===null){
                    //           return {'order':0}
                    //         }else{
                    //           currentData['order']=Number(currentData['order'])+1
                    //           return currentData; // 把單號+1，然後存回去
                    //         }
                    //       },(error, committed, snapshot)=>{
                    //         if (error) {
                    //             this.setState({ 
                    //               printErrorMessage:'請再列印一次!', 
                    //             })
                    //         } else if (committed) {
                    //             //console.log(snapshot.val())
                    //             var currentData=snapshot.val()
                    //             deliveryNumber=(Number(currentData['order'])+1).toString()              
                    //             var Space=4-deliveryNumber.length 
                    //             for (var i = 0; i < Space; i++) {
                    //               deliveryNumber ='0' + deliveryNumber;
                    //             }
                    //             var year=todayTitle()[1].toString().slice(2)
                    //             deliveryNumber='JPRA'+year+todayTitle()[2]+todayTitle()[3]+deliveryNumber.toString()
                                
                    //             process()
                    //         } 
                            
                            
    
                    //       }
                    // )
              }
        
        
        }
  }  
 
}

//這是給下面表格每一單需要重印標籤的時候用的
//基本上判斷是哪個國家的單，叫出資料以後，直接呼叫對應的印製方式
rePrint(e){
   let targetDeliveryNumber=e.target.name
   ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+targetDeliveryNumber).on('value', (snapshot)=> {
      if(snapshot.val()){
        var target=snapshot.val()
        pdfmake.vfs = pdffonts.pdfMake.vfs;
        let LabelArr = [];
        let content='';
        let contentCalculateBase=[];
        

        if(target['國別']==='SG'){
            let logo=LogoImg();
            let currency='SGD$' 
            Object.keys( target['內容物']).forEach((key)=> {
              if(key===String(target['總箱數'])){
                let boxNumber=key
                let Space=2-boxNumber.length 
                for (let i = 0; i < Space; i++) {
                  boxNumber ='0' + boxNumber;
                }
                let unitDeliveryNumber=target['poNumber']+boxNumber
                let dataURL=textToBase64Barcode(unitDeliveryNumber)
                let thisPO=target['內容物'][key]
                let amount=Number((thisPO['amount']/22).toFixed(1))
        
                let content='';
                
                Object.keys( thisPO['content']).forEach((key)=> {
                  let item=thisPO['content'][key]['name']+'*'+thisPO['content'][key]['number']+'  '
                  content=content+item
                })
        
                let Label=[
                  { table: {
                    widths: ['auto', '*', '*', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:100, 
                        height:20,
                        alignment: 'center',
                        colSpan: 3},
                        '',
                        '',
                        { rowSpan: 2,
                          colSpan: 3,
                          text: target['國別'],
                          style: 'head',  
                          width:100, 
                          alignment: 'center',          
                        },
                        '',
                        ''
        
                      ],
                      [{ image:dataURL,
                        colSpan: 3,
                        width:140, 
                        height:60,
                        alignment: 'center',
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
                      //SG這一格是設成zipcode
                      [{ text: 'A/C NO:'},
                      {text: target['收件人郵遞區號'],
                        colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{ text: 'PO:'},
                      {text: target['deliveryNumber'],colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Shipper:'},
                      {text:'ROBINMAY',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Consignee:'},
                      {text: target.contact,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'TEL:'},
                      {text: target.receiverPhoneNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Address:',rowSpan: 2},
                      {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{},
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      // [
                      // //   {text: 'Declared Value:'},
                      // // {text: currency+amount,colSpan: 5},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {}
                      // ],
        
                      [{text: 'Weight:'},
                      //KGS前面後一格，看起來比較好看
                      {text:thisPO['weight']+' KGS   -'+key+'/'+target['總箱數']+'Boxes',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{text: 'Description:'},
                      {text: content,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                    ]
                  },
                  },     
                ];
                LabelArr.push(Label);
        
              }else if(key==='1'){
                let unitDeliveryNumber=target['deliveryNumber']
                let dataURL=textToBase64Barcode(unitDeliveryNumber)
                let thisPO= target['內容物'][key]
                let amount=Number((thisPO['amount']/22).toFixed(1))
        
                let content='';
                
                Object.keys( thisPO['content']).forEach((key)=> {
                  let item=thisPO['content'][key]['name']+'*'+thisPO['content'][key]['number']+'  '
                  content=content+item      
                })
                
                let Label=[
                  { table: {
                    widths: ['auto', '*', '*', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:100, 
                        height:20,
                        alignment: 'center',
                        colSpan: 3},
                        '',
                        '',
                        { rowSpan: 2,
                          colSpan: 3,
                          text: target['國別'],
                          style: 'head',  
                          width:100, 
                          alignment: 'center',          
                        },
                        '',
                        ''
        
                      ],
                      [{ image:dataURL,
                        colSpan: 3,
                        width:140, 
                        height:60,
                        alignment: 'center',
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
                      //SG這一格是設成zipcode
                      [{ text: 'A/C NO:'},
                      {text: target['收件人郵遞區號'],
                        colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{ text: 'PO:'},
                      {text: target['deliveryNumber'],colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Shipper:'},
                      {text:'ROBINMAY',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Consignee:'},
                      {text: target.contact,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'TEL:'},
                      {text: target.receiverPhoneNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Address:',rowSpan: 2},
                      {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{},
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      // [
                      // //   {text: 'Declared Value:'},
                      // // {text: currency+amount,colSpan: 5},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {}
                      // ],
        
                      [{text: 'Weight:'},
                      //KGS前面後一格，看起來比較好看
                      {text:thisPO['weight']+' KGS   -'+key+'/'+target['總箱數']+'Boxes',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{text: 'Description:'},
                      {text: content,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                    ]
                  },
                  pageBreak: 'after'
                },     
                ];
                LabelArr.push(Label);
                
        
              }else{
                let boxNumber=key
                let Space=2-boxNumber.length 
                for (let i = 0; i < Space; i++) {
                  boxNumber ='0' + boxNumber;
                }
                let unitDeliveryNumber=target['poNumber']+boxNumber
                let dataURL=textToBase64Barcode(unitDeliveryNumber)
                let thisPO=target['內容物'][key]
                let amount=Number((thisPO['amount']/22).toFixed(1))
        
                let content='';
                
                Object.keys( thisPO['content']).forEach((key)=> {
                  let item=thisPO['content'][key]['name']+'*'+thisPO['content'][key]['number']+'  '
                  content=content+item
                })
        
                let Label=[
                  { table: {
                    widths: ['auto', '*', '*', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:100, 
                        height:20,
                        alignment: 'center',
                        colSpan: 3},
                        '',
                        '',
                        { rowSpan: 2,
                          colSpan: 3,
                          text: target['國別'],
                          style: 'head',  
                          width:100, 
                          alignment: 'center',          
                        },
                        '',
                        ''
        
                      ],
                      [{ image:dataURL,
                        colSpan: 3,
                        width:140, 
                        height:60,
                        alignment: 'center',
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
                      //SG這一格是設成zipcode
                      [{ text: 'A/C NO:'},
                      {text: target['收件人郵遞區號'],
                        colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{ text: 'PO:'},
                      {text: target['deliveryNumber'],colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Shipper:'},
                      {text:'ROBINMAY',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Consignee:'},
                      {text: target.contact,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'TEL:'},
                      {text: target.receiverPhoneNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      [{text: 'Address:',rowSpan: 2},
                      {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{},
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                      // [
                      // //   {text: 'Declared Value:'},
                      // // {text: currency+amount,colSpan: 5},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {}
                      // ],
        
                      [{text: 'Weight:'},
                      //KGS前面後一格，看起來比較好看
                      {text:thisPO['weight']+' KGS   -'+key+'/'+target['總箱數']+'Boxes',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{text: 'Description:'},
                      {text: content,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
        
                    ]
                  },
                  pageBreak: 'after'
                },     
                ];
                LabelArr.push(Label);
        
        
              }
            })

        }else if(target['國別']==='MY'){
            //處理馬來西亞的部分
            for(var i=0;i<target.itemNumber;i++){
                    var number=i+1
                    var item=target['itemName'+number]+'*'+target['itemNumber'+number]+'  '
                    content=content+item
                    contentCalculateBase[target['itemName'+number]]={'Number':target['itemNumber'+number],'Amount':target['itemAmount'+number]}           
            }
            let logo=LineClearLogoImg();
            let weight=(Number(target['總重量-公斤'])/Number(target['總箱數'])).toFixed(1)
            let labelNumber=target.MYdeliveryData
            let barCodeInfo=target.MYdeliveryData['WayBill']
            
            for(let i=0;i<target['總箱數'];i++){
              if(i===target['總箱數']-1){ 
                let Label= [ 
                  { table: {
                    widths: ['auto', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:70, 
                        height:10,
                        alignment: 'left',
                        colSpan: 4,
                        border: [false, false, false, true],},
                        '',
                        '',
                        '',
                        
            
                      ],
                      [
                        
                        {
                          
                          table: {
                            
                            body: [
                              [{ 
                        
                                qr: barCodeInfo[i], 
                                fit: '80', 
                                alignment: 'center', 
                                margin: [0, 10, 0, 5],
                                colSpan:3,
                                border: [false, false, false, false],        
                              },
                              '',
                              ''],
                              [
                                { text:'',
                                  border: [false, false, false, false]},
                                { text:'',
                                border: [false, false, false, false]},
                                { text:'',
                                border: [false, false, false, false]}
                              ],
                              [
                                  { text: 'PCS',
                                  fontSize: 6,
                                  alignment: 'left',
                                  bold: true,
                                  border: [true, true, false, false],
                                  },
                                  { text: '',
                                  
                                  border: [false, true, false, false],
                                  },
                                  { text: 'M',
                                  fontSize: 6,
                                  alignment: 'right',
                                  bold: true,
                                  border: [false, true, true, false],
                                  },


                              ],
                              [{  text: (i+1)+'/'+target['總箱數'],
                                  colSpan:3,
                                  fontSize: 18,
                                  alignment: 'center',
                                  bold: true,
                                  border: [true, false, true, true],
                                },
                                '',
                                ''
                              ],
                              
                            ],
                          
                          },
                          border: [false, false, false, false],
                          layout:{
                            hLineColor:'#999999',
                            vLineColor:'#999999',
                          

                          },
                          
                          
                          
                        }, 
                        {
                          
                            table: {
                              
                              body: [
                                [{ image:textToBase64Barcode(barCodeInfo[i],undefined,undefined,60),
                                  colSpan: 3,
                                  height:50,
                                  width:170,
                                  alignment: 'center',
                                  border: [false, false, false, false],
                                },
                                '',
                                ''],
                                //空白格
                                [{text:'',
                                  height:1,
                                  border: [false, false, false, false],
                                  colSpan: 3,
                                },'',''],
                                //第一排
                                [{text:'Origin Agent',
                                  alignment: 'left',
                                  bold: true,
                                  fontSize: 6,
                                  border: [true, true, true, false],
                                }, 
                                {text:'Origin Depot',
                                alignment: 'left',
                                bold: true,
                                fontSize: 6,
                                border: [true, true, true, false],
                                }, 
                                {text:'Origin Hub',
                                alignment: 'left',
                                bold: true,
                                  fontSize: 6,
                                  border: [true, true, true, false],
                                }],
                                //第二排(資料排)
                                [
                                  { text:'',
                                  alignment: 'center',
                                  bold: true,
                                  fontSize: 15,
                                  border: [true, false, true, false],
                                  }, 
                                  { text:labelNumber['PickupBranchCode'],
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, false],
                                  }, 
                                  { text:labelNumber['PickupHubCode'],
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, false],
                                  }
                                ],
                                //第三排
                                [
                                  { text:'Dest. Hub',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }, 
                                  { text:'Dest. Depot',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }, 
                                  { text:'Dest. Agent',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }
                                ],
                                //第四排(資料排)
                                [
                                  { text:labelNumber['DeliveryHubCode'],
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }, 
                                  { text:labelNumber['DeliveryBranchCode'],
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }, 
                                  { text:'',
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }
                                ]

                              ]
                            },
                            border: [false, false, false, false],
                            layout:{
                                    hLineColor:'#999999',
                                    vLineColor:'#999999',
                                  

                            },
                            colSpan:3,
                            
                            
                        },
                        
                        '',
                        '',
                      
                      ],
                      [
                        { text:'From',
                          alignment: 'left',
                          bold: true,
                          fontSize: 12,
                          colSpan:2,
                          border: [true, true, false, false],
                        },
                        '',
                        { text:'Account No.: N6900038',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:2,
                          border: [false, true, true, false],
                        },
                        ''

                      ],
                      [{  text:'FREEGO TRADING AND LOGISTICS',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          width:220,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'MR TIEN',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'60126368616',
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:2,
                          border: [true, false, false, true],
                      },
                      '',
                      {  text:'Cust. Ref. No.:',
                          colSpan:2,
                          fontSize: 8,
                          bold: true,
                          border: [false, false, true, true],
                      },
                      ''

                      ],
                      [
                        { text:'To',
                          alignment: 'left',
                          bold: true,
                          fontSize: 12,
                          colSpan:2,
                          border: [true, true, false, false],
                        },
                        '',
                        { text:'',
                          colSpan:2,
                          border: [false, true, true, false],
                        },
                        ''

                      ],
                      [{  text:target['收件地址'],
                          alignment: 'left',
                          width:220,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:target.contact,
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:target.receiverPhoneNumber,
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, true],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'Weight(kg):'+ weight+'   |Pcs:'+  target['總箱數']  + '   |Type:Package',
                          alignment: 'left',
                          bold: true,
                          fontSize: 6,
                          colSpan:2,
                          border: [true, true, false, true],
                      },
                      '',
                      {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                          alignment: 'right',
                          colSpan:2,
                          fontSize: 6,
                          bold: true,
                          border: [false, true, true, true],
                      },
                      '',
                      

                      ],
                      [
                        {text:'',
                        colSpan:4,
                        height:3,
                        border: [false, true,false, true]},
                        '',
                        '',
                        ''
                      ],
                      [
                        {text:'Acknowledgement by Recipient',
                        fontSize: 8,
                        colSpan:4,
                        height:3,
                        bold: true,
                        border: [true, true,true, true]},
                        '',
                        '',
                        ''
                      ],
                      [
                        {text:'',
                        colSpan:2,
                        border: [true, true,false, false]},
                        '',
                        {text:'Name :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, true,true, false]},
                        ''
                      ],
                      [
                        {text:'Signature &  Company Stamp',
                        alignment: 'center',
                        color:'#b3b3b3',
                        fontSize: 8,
                        colSpan:2,
                        border: [true, false,false, false]},
                        '',
                        {text:'Date & Time :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, false,true, false]},
                        ''
                      ],
                      [
                        {text:'',
                        colSpan:2,
                        border: [true, false,false, true]},
                        '',
                        {text:'IC / Passport :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, false,true, true]},
                        ''
                      ],

                      
                      [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                      fontSize: 6,
                      margin: [0, 1, 0, 0],
                      colSpan: 4,
                      bold: true,
                      border: [false, false, false, false],
                      },
                      '',
                      '',
                      ''
                      ] 
                    ],
                    
                  },
                  }  
                  ]
                LabelArr.push(Label);
              }else{
                
                let Label= [ 
                  { table: {
                    widths: ['auto', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:70, 
                        height:10,
                        alignment: 'left',
                        colSpan: 4,
                        border: [false, false, false, true],},
                        '',
                        '',
                        '',
                        
            
                      ],
                      [
                        
                        {
                          
                          table: {
                            
                            body: [
                              [{ 
                        
                                qr: barCodeInfo[i], 
                                fit: '80', 
                                alignment: 'center', 
                                margin: [0, 10, 0, 5],
                                colSpan:3,
                                border: [false, false, false, false],        
                              },
                              '',
                              ''],
                              [
                                { text:'',
                                  border: [false, false, false, false]},
                                { text:'',
                                border: [false, false, false, false]},
                                { text:'',
                                border: [false, false, false, false]}
                              ],
                              [
                                  { text: 'PCS',
                                  fontSize: 6,
                                  alignment: 'left',
                                  bold: true,
                                  border: [true, true, false, false],
                                  },
                                  { text: '',
                                  
                                  border: [false, true, false, false],
                                  },
                                  { text: 'M',
                                  fontSize: 6,
                                  alignment: 'right',
                                  bold: true,
                                  border: [false, true, true, false],
                                  },


                              ],
                              [{  text: (i+1)+'/'+target['總箱數'],
                                  colSpan:3,
                                  fontSize: 18,
                                  alignment: 'center',
                                  bold: true,
                                  border: [true, false, true, true],
                                },
                                '',
                                ''
                              ],
                              
                            ],
                          
                          },
                          border: [false, false, false, false],
                          layout:{
                            hLineColor:'#999999',
                            vLineColor:'#999999',
                          

                          },
                          
                          
                          
                        }, 
                        {
                          
                            table: {
                              
                              body: [
                                [{ image:textToBase64Barcode(barCodeInfo[i],undefined,undefined,60),
                                  colSpan: 3,
                                  height:50,
                                  width:170,
                                  alignment: 'center',
                                  border: [false, false, false, false],
                                },
                                '',
                                ''],
                                //空白格
                                [{text:'',
                                  height:1,
                                  border: [false, false, false, false],
                                  colSpan: 3,
                                },'',''],
                                //第一排
                                [{text:'Origin Agent',
                                  alignment: 'left',
                                  bold: true,
                                  fontSize: 6,
                                  border: [true, true, true, false],
                                }, 
                                {text:'Origin Depot',
                                alignment: 'left',
                                bold: true,
                                fontSize: 6,
                                border: [true, true, true, false],
                                }, 
                                {text:'Origin Hub',
                                alignment: 'left',
                                bold: true,
                                  fontSize: 6,
                                  border: [true, true, true, false],
                                }],
                                //第二排(資料排)
                                [
                                  { text:'',
                                  alignment: 'center',
                                  bold: true,
                                  fontSize: 15,
                                  border: [true, false, true, false],
                                  }, 
                                  { text:labelNumber['PickupBranchCode'],
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, false],
                                  }, 
                                  { text:labelNumber['PickupHubCode'],
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, false],
                                  }
                                ],
                                //第三排
                                [
                                  { text:'Dest. Hub',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }, 
                                  { text:'Dest. Depot',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }, 
                                  { text:'Dest. Agent',
                                    alignment: 'left',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 6,
                                    border: [true, true, true, false],
                                  }
                                ],
                                //第四排(資料排)
                                [
                                  { text:labelNumber['DeliveryHubCode'],
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }, 
                                  { text:labelNumber['DeliveryBranchCode'],
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }, 
                                  { text:'',
                                    alignment: 'center',
                                    fillColor: '#000000',
                                    color:'#ffffff',
                                    bold: true,
                                    fontSize: 15,
                                    border: [true, false, true, true],
                                  }
                                ]

                              ]
                            },
                            border: [false, false, false, false],
                            layout:{
                                    hLineColor:'#999999',
                                    vLineColor:'#999999',
                                  

                            },
                            colSpan:3,
                            
                            
                        },
                        
                        '',
                        '',
                      
                      ],
                      [
                        { text:'From',
                          alignment: 'left',
                          bold: true,
                          fontSize: 12,
                          colSpan:2,
                          border: [true, true, false, false],
                        },
                        '',
                        { text:'Account No.: N6900038',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:2,
                          border: [false, true, true, false],
                        },
                        ''

                      ],
                      [{  text:'FREEGO TRADING AND LOGISTICS',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          width:220,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'MR TIEN',
                          alignment: 'left',
                          bold: true,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'60126368616',
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:2,
                          border: [true, false, false, true],
                      },
                      '',
                      {  text:'Cust. Ref. No.:',
                          colSpan:2,
                          fontSize: 8,
                          bold: true,
                          border: [false, false, true, true],
                      },
                      ''

                      ],
                      [
                        { text:'To',
                          alignment: 'left',
                          bold: true,
                          fontSize: 12,
                          colSpan:2,
                          border: [true, true, false, false],
                        },
                        '',
                        { text:'',
                          colSpan:2,
                          border: [false, true, true, false],
                        },
                        ''

                      ],
                      [{  text:target['收件地址'],
                          alignment: 'left',
                          width:220,
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:target.contact,
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, false],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:target.receiverPhoneNumber,
                          alignment: 'left',
                          fontSize: 8,
                          colSpan:4,
                          border: [true, false, true, true],
                      },
                      '',
                      '',
                      ''

                      ],
                      [{  text:'Weight(kg):'+weight+'   |Pcs:'+  target['總箱數']  + '   |Type:Package',
                          alignment: 'left',
                          bold: true,
                          fontSize: 6,
                          colSpan:2,
                          border: [true, true, false, true],
                      },
                      '',
                      {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                          alignment: 'right',
                          colSpan:2,
                          fontSize: 6,
                          bold: true,
                          border: [false, true, true, true],
                      },
                      '',
                      

                      ],
                      [
                        {text:'',
                        colSpan:4,
                        height:3,
                        border: [false, true,false, true]},
                        '',
                        '',
                        ''
                      ],
                      [
                        {text:'Acknowledgement by Recipient',
                        fontSize: 8,
                        colSpan:4,
                        height:3,
                        bold: true,
                        border: [true, true,true, true]},
                        '',
                        '',
                        ''
                      ],
                      [
                        {text:'',
                        colSpan:2,
                        border: [true, true,false, false]},
                        '',
                        {text:'Name :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, true,true, false]},
                        ''
                      ],
                      [
                        {text:'Signature &  Company Stamp',
                        alignment: 'center',
                        color:'#b3b3b3',
                        fontSize: 8,
                        colSpan:2,
                        border: [true, false,false, false]},
                        '',
                        {text:'Date & Time :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, false,true, false]},
                        ''
                      ],
                      [
                        {text:'',
                        colSpan:2,
                        border: [true, false,false, true]},
                        '',
                        {text:'IC / Passport :',
                        fontSize: 8,
                        colSpan:2,
                        border: [false, false,true, true]},
                        ''
                      ],

                      
                      [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                      fontSize: 6,
                      margin: [0, 1, 0, 0],
                      colSpan: 4,
                      bold: true,
                      border: [false, false, false, false],
                      },
                      '',
                      '',
                      ''
                      ] 
                    ],
                    
                  },
                  
                  pageBreak: 'after'
                  }  
                  ]
                LabelArr.push(Label);
              }
            }

            //馬來西亞舊標籤重印
            // for(var i=0;i<target.itemNumber;i++){
            //   var number=i+1
            //   var item=target['itemName'+number]+'*'+target['itemNumber'+number]+'  '
            //   content=content+item
            //   contentCalculateBase[target['itemName'+number]]={'Number':target['itemNumber'+number],'Amount':target['itemAmount'+number]}           
            // }
            
            // var dataURL=textToBase64Barcode(target.deliveryNumber);
            // var acNumber='N6900038'
            // var currency='MYR'
            // var amount=Number((target.amount/7.2).toFixed(1))
            
            // for(let i=0;i<target['總箱數'];i++){
            //   if(i===Number(target['總箱數'])-1){ 
            //     let Label=[
            //       { table: {
            //         widths: ['auto', '*', '*', '*', '*', '*'],
            //         //下面是要做一開始的logo、barcode、和國碼標
            //         body: [
            //           [
            //             {
            //             // you'll most often use dataURI images on the browser side
            //             // if no width/height/fit is provided, the original size will be used
            //             image:logo,
            //             width:100, 
            //             height:20,
            //             alignment: 'center',
            //             colSpan: 3},
            //             '',
            //             '',
            //             { rowSpan: 2,
            //               colSpan: 3,
            //               text: target['國別'],
            //               style: 'head',  
            //               width:100, 
            //               alignment: 'center',          
            //             },
            //             '',
            //             ''
            
            //           ],
            //           [{ image:dataURL,
            //             colSpan: 3,
            //             width:140, 
            //             height:60,
            //             alignment: 'center',
            //           },
            //           {},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           //這是馬來西亞標籤特別要加的
            //           [{ text: 'A/C NO:'},
            //           {text: acNumber,
            //             colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{ text: 'PO:'},
            //           {text:target.poNumber,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Shipper:'},
            //           {text:'ROBINMAY',colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Consignee:'},
            //           {text: target.contact,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'TEL:'},
            //           {text: target.receiverPhoneNumber,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Address:',rowSpan: 2},
            //           {text: target['收件地址'],rowSpan: 2,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{},
            //           {},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Declared Value:'},
            //           {text: currency+amount,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Weight:'},
            //           //KGS前面後一格，看起來比較好看
            //           {text:target['總重量-公斤']+' KGS   -'+target['總箱數']+' Boxes',colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{text: 'Description:'},
            //           {text: content,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //         ]
            //       },
            //       },     
            //     ];
            //     LabelArr.push(Label);
            //   }else{
            //     let Label=[
            //       { table: {
            //         widths: ['auto', '*', '*', '*', '*', '*'],
            //         //下面是要做一開始的logo、barcode、和國碼標
            //         body: [
            //           [
            //             {
            //             // you'll most often use dataURI images on the browser side
            //             // if no width/height/fit is provided, the original size will be used
            //             image:logo,
            //             width:100, 
            //             height:20,
            //             alignment: 'center',
            //             colSpan: 3},
            //             '',
            //             '',
            //             { rowSpan: 2,
            //               colSpan: 3,
            //               text:  target['國別'],
            //               style: 'head',  
            //               width:100, 
            //               alignment: 'center',          
            //             },
            //             '',
            //             ''
            
            //           ],
            //           [{ image:dataURL,
            //             colSpan: 3,
            //             width:140, 
            //             height:60,
            //             alignment: 'center',
            //           },
            //           {},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           //這是馬來西亞標籤特別要加的
            //           [{ text: 'A/C NO:'},
            //           {text: acNumber,
            //             colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{ text: 'PO:'},
            //           {text:target.poNumber,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Shipper:'},
            //           {text:'ROBINMAY',colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Consignee:'},
            //           {text: target.contact,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'TEL:'},
            //           {text: target.receiverPhoneNumber,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Address:',rowSpan: 2},
            //           {text: target['收件地址'],rowSpan: 2,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{},
            //           {},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Declared Value:'},
            //           {text: currency+amount,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //           [{text: 'Weight:'},
            //           //KGS前面後一格，看起來比較好看
            //           {text:target['總重量-公斤']+' KGS   -'+target['總箱數']+' Boxes',colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            //           [{text: 'Description:'},
            //           {text: content,colSpan: 5},
            //           {},
            //           {},
            //           {},
            //           {}
            //           ],
            
            //         ]
            //       },
            //       pageBreak: 'after'
            //     },     
            //     ];
            //     LabelArr.push(Label);
            //   }

            // }
        }else if(target['國別']==='JP'){
            //處理馬來西亞的部分
            for(var i=0;i<target.itemNumber;i++){
              var number=i+1
              var item=target['itemName'+number]+'*'+target['itemNumber'+number]+'  '
              content=content+item
              contentCalculateBase[target['itemName'+number]]={'Number':target['itemNumber'+number],'Amount':target['itemAmount'+number]}           
            }
            
            let logo=LogoImg();
            var dataURL=textToBase64Barcode(target.deliveryNumber);
            var currency='NTD$'
            let amount=Number((target.amount/1).toFixed(1))
            
            
            for(let i=0;i<target['總箱數'];i++){
              if(i===Number(target['總箱數'])-1){ 
                let Label=[
                  { table: {
                    widths: ['auto', '*', '*', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:100, 
                        height:20,
                        alignment: 'center',
                        colSpan: 3},
                        '',
                        '',
                        { rowSpan: 2,
                          colSpan: 3,
                          text: target['國別'],
                          style: 'head',  
                          width:100, 
                          alignment: 'center',          
                        },
                        '',
                        ''
            
                      ],
                      [{ image:dataURL,
                        colSpan: 3,
                        width:140, 
                        height:60,
                        alignment: 'center',
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
                      //這是馬來西亞標籤特別要加的
                      [{ text: 'A/C NO:'},
                      {text: '',
                        colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{ text: 'PO:'},
                      {text:target.poNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Shipper:'},
                      {text:'ROBINMAY',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Consignee:'},
                      {text: target.contact,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'TEL:'},
                      {text: target.receiverPhoneNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Address:',rowSpan: 2},
                      {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{},
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      // [
                      // //   {text: 'Declared Value:'},
                      // // {text: currency+amount,colSpan: 5},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {}
                      // ],
            
                      [{text: 'Weight:'},
                      //KGS前面後一格，看起來比較好看
                      {text:target['總重量-公斤']+' KGS   -'+target['總箱數']+' Boxes',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{text: 'Description:'},
                      {text: content,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                    ]
                  },
                  },     
                ];
                LabelArr.push(Label);
              }else{
                let Label=[
                  { table: {
                    widths: ['auto', '*', '*', '*', '*', '*'],
                    //下面是要做一開始的logo、barcode、和國碼標
                    body: [
                      [
                        {
                        // you'll most often use dataURI images on the browser side
                        // if no width/height/fit is provided, the original size will be used
                        image:logo,
                        width:100, 
                        height:20,
                        alignment: 'center',
                        colSpan: 3},
                        '',
                        '',
                        { rowSpan: 2,
                          colSpan: 3,
                          text:  target['國別'],
                          style: 'head',  
                          width:100, 
                          alignment: 'center',          
                        },
                        '',
                        ''
            
                      ],
                      [{ image:dataURL,
                        colSpan: 3,
                        width:140, 
                        height:60,
                        alignment: 'center',
                      },
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
                      //這是馬來西亞標籤特別要加的
                      [{ text: 'A/C NO:'},
                      {text: '',
                        colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{ text: 'PO:'},
                      {text:target.poNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Shipper:'},
                      {text:'ROBINMAY',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Consignee:'},
                      {text: target.contact,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'TEL:'},
                      {text: target.receiverPhoneNumber,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      [{text: 'Address:',rowSpan: 2},
                      {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{},
                      {},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                      // [
                      //   //   {text: 'Declared Value:'},
                      //   // {text: currency+amount,colSpan: 5},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {},
                      // {}
                      // ],
            
                      [{text: 'Weight:'},
                      //KGS前面後一格，看起來比較好看
                      {text:target['總重量-公斤']+' KGS   -'+target['總箱數']+' Boxes',colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
                      [{text: 'Description:'},
                      {text: content,colSpan: 5},
                      {},
                      {},
                      {},
                      {}
                      ],
            
                    ]
                  },
                  pageBreak: 'after'
                },     
                ];
                LabelArr.push(Label);
              }

            }




        }

        pdfmake.fonts = {
          ch: {
          normal: 'kaiu.ttf',
          bold: 'kaiu.ttf',
          italics: 'kaiu.ttf',
          bolditalics: 'kaiu.ttf'
        }};
  
      var docDefinition = {
              content:LabelArr,
              defaultStyle: {
              font: 'ch',
              fontSize: 11
              } ,
              styles: {
                head: {
                  
                  fontSize: 66,
                  bold: true
                },
  
              },
  
              // a string or { width: number, height: number }
              // 下面是10*10的標籤設計
              pageSize: { width: 283, height: 425},
              
  
              // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
              pageMargins: [ 3, 3,3, 0 ],
      };
      
      //準備出單印製
      pdfmake.createPdf(docDefinition).print();

      }

   })

}

//品號的部分要處理一下，讓客人可以少輸幾個字
//目前不確定恩碩要不要單號
poNumberChange(e){
  var poNumber=e.target.value;
  this.setState({ 
    poNumber: poNumber,})
  
  if(poNumber.length>10){
    var poPredefinedNumber=poNumber.substr(0, 9)
    if(this.state.poPredefinedNumber!==poPredefinedNumber){
      this.setState({ 
        poPredefinedNumber:poPredefinedNumber });
    }
  }

}

//印製歷史標籤
// historyExportFile(){
//   //先把客人要印的目標單號調出來
//   var target= this.state.historyRecord
    
//   var allPO=''

//   ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).on('value', (snapshot)=> {
//     //先把database值拿出來    
//         if(snapshot.val()==null){
//           this.setState({historyErrorMessage:'找不到資料'})
//         }else{
//           allPO=snapshot.val()
//           var target={ 
//             'receiverPhoneNumber':allPO.收件連絡電話,
//             'contact':allPO.收件人姓名,
//             'address':allPO.收件地址,
//             'poNumber':allPO.poNumber, 
//             'boxNumber':allPO.總箱數,
//             'weight':allPO['總重量-公斤'],
//             'amount':allPO.amount,
//             'itemNumber':allPO.itemNumber,
//             'zipCode':allPO.收件人郵遞區號,

//             'historyRecord':'',
//           }
          
//           for(var i=0;i<allPO.itemNumber;i++){
//             var number=i+1
//             target['itemName'+number]=allPO['itemName'+number]
//             target['itemNumber'+number]=allPO['itemNumber'+number]
//             target['itemAmount'+number]=allPO['itemAmount'+number]
//           }

//           this.setState(target); 
                   
//         }
//   })

// }

//打開刪除確認頁
deleteOpen(box,e){
  var target=e.target.name
  this.setState({
    deleteFinish : true,
    deleteTarget : target,
    deleteBoxNumber: Number(box) 
  })
}

//關閉刪除確認頁
deleteClose=()=>{
  this.setState({
    deleteFinish : false
  })
}
//刪除歷史資料
deleteRecord(){
  this.setState({
    deleteFinish : false
  })

  let target=this.state.deleteTarget
  let box=this.state.deleteBoxNumber
  //刪訂單要刪兩個地方，一個是OrderInfo，一個是PrepareToShip
  if(target.slice(0,2)==='SG'){
      // 因為target就是首張單號，所以OrderInfo直接刪就好
      ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+target).set({})
      
      //接下來要處理 PrepareToShip的部分
      //要一箱一箱刪
      for(let i=0;i<Number(box);i++){
        let boxNumber=i+1
        if(boxNumber===1){

            ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).set({})

        }else{
            //這裡有問題，因為目前的target已經改成15碼，要處理
            let Space=2-String(boxNumber).length 
            for (let i = 0; i < Space; i++) {
              boxNumber ='0' + boxNumber;
            }
            let unitDeliveryNumber=target.slice(0,13)+boxNumber
            ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+unitDeliveryNumber).set({})
        }
      }

  }else{
      ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+target).set({})
      ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).set({})
  }
}

rechangePOContent(deliveryNumber){
  ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid+'/'+deliveryNumber).on('value', (snapshot)=> {
    if(snapshot.val()){
       let target=snapshot.val()
       if(target['國別']==='SG'){
            this.setState({ 
              deliveryNation:target.國別,
              receiverPhoneNumber:target.receiverPhoneNumber,
              contact:target.contact,
              address:target.收件地址,
              zipCode:target.收件人郵遞區號,
              poNumber:target.poNumber,
              oldBoxNumber:target.總箱數,
              boxNumber:target.總箱數,
              weight:'',
              amount:0,
              itemNumber:1,
              itemName1:'包',
              itemNumber1:'',
              itemAmount1:'',
              SGContent:target.內容物,
                           
            }); 

       }else if(target['國別']==='JP'){
            let content={ 
              deliveryNation:target.國別,
              receiverPhoneNumber:target.receiverPhoneNumber,
              contact:target.contact,
              address:target.收件地址,
              zipCode:target.收件人郵遞區號,
              poNumber:target.poNumber,
              boxNumber:target.總箱數,
              weight:target['總重量-公斤'],
              amount:target.報關總值,
              itemNumber:target.itemNumber,
              payment:target.payment
            }

            for(let i=0; i<target.itemNumber;i++){
              var number=i+1
              content['itemName'+number]=target['itemName'+number]
              content['itemNumber'+number]=target['itemNumber'+number]
              content['itemAmount'+number]=target['itemAmount'+number]
            }

        this.setState(content);

       }else{
          // 這裡是針對MY等其他國家
          let content={ 
            deliveryNation:target.國別,
            receiverPhoneNumber:target.receiverPhoneNumber,
            contact:target.contact,
            address:target.收件地址,
            zipCode:target.收件人郵遞區號,
            poNumber:target.poNumber,
            boxNumber:target.總箱數,
            weight:target['總重量-公斤'],
            amount:target.報關總值,
            itemNumber:target.itemNumber,
          }

          for(let i=0; i<target.itemNumber;i++){
            var number=i+1
            content['itemName'+number]=target['itemName'+number]
            content['itemNumber'+number]=target['itemNumber'+number]
            content['itemAmount'+number]=target['itemAmount'+number]
          }

          this.setState(content);

       }
    }

  })
}

//把分項金額加起來，確認總額對不對
handleItemAmountChange(e){
  var num=this.state.itemNumber
  var target=e.target.name.slice(10,14)
  
  var totalAmount=0
  for(let i=0;i<num;i++){
    let number=i+1
    if(number===Number(target)){
      totalAmount=Number(totalAmount)+Number(e.target.value)
    }else{
      totalAmount=Number(totalAmount)+Number(this.state['itemAmount'+number]) 
    }
    
  }
  this.setState({
    [e.target.name]: e.target.value,
    amount:totalAmount,
  })

}

render() {
  
    //下面是報關內容的填寫介面
    
    let orderInput = [];
    if (this.state.deliveryNation==='SG'){
      //boxnumber有變動，那我要考慮現在是新開單，還是要修改訂單
      //如果content為空，那就是新開單，那我就要新建全部資料
      //如果不為空，那就是要改單，我要顯示資料內容
      if(this.state.SGContent===''){
        //當SGoontent是空的時候，那就只要把資料設好，填回state，就會改跑else，在else裡面去設格式就好
        let content={}
        for(let i=0;i<this.state.boxNumber;i++){
          let number=i+1
          content[number]={}
          //每一箱的報關總價預設是0
          content[number]['amount']=0
          content[number]['content']={}
          //不存在的object不能建立，所以要分層建立
          content[number]['content']['1']={
            name:'包',
            number:'',
            amount:''
          } 
           
        }
        
        this.setState({
          SGContent:content
        })


      }else{
       //這個地方要放客人改單的處理碼
        let content=this.state.SGContent
        
        Object.keys(content).forEach((key)=> {
            //現在開始處理每一箱
            let box=content[key];
            let itemInput=[]
            Object.keys(box['content']).forEach((itemkey)=> {
                  
                  itemInput.push(
                      <Form.Group  style={{ height: '2.2em',}} >
                                      <label style={{'font-size':'12px' }}>{itemkey}</label>
                                      <Form.Field>
                                          <label style={{'font-size':'12px' }}>品名</label>
                                          <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold'}} placeholder='請輸入品名' value={box['content'][itemkey]['name']} name={'name'} onChange={(e)=>this.SGChangeContent(key,itemkey,e)}/>
                                      </Form.Field>
                                    
                                      <Form.Field>
                                          <label style={{'font-size':'12px' }}>數量</label>
                                          <input style={{'font-size':'14px', height: '2em',width:80 ,'font-weight':'bold'}} placeholder='件數' value={box['content'][itemkey]['number']} name={'number'} onChange={(e)=>this.SGChangeContent(key,itemkey,e)}/>
                                      </Form.Field>

                                      <Form.Field>
                                          <label style={{'font-size':'12px' }}>報關總值$NT</label>
                                          <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold' }} placeholder='台幣金額' value={box['content'][itemkey]['amount']} name={'amount'} onChange={(e)=>this.SGChangeBoxAmount(key,itemkey,e)}/>
                                      </Form.Field>
                                      
                      </Form.Group>
                    )
            })
            orderInput.push(
              <div>
                <Form.Group>
                   
                    <Form.Field  width='2'>     
                          <Button icon='close' label='刪除此箱'  size='mini' color='grey'  onClick={()=>this.cutBox(key)} style={{ 'font-size':'10px' }}/>
                    </Form.Field>
                    <Form.Field  width='2'>
                          <Label color='olive' size='large' tag style={{ 'font-size':'15px' }} > 第{key}箱  </Label>
                    </Form.Field>
                    <Form.Field   width='4'>
                            
                          <Button icon='plus' label='加一個品項'  size='mini' color='orange'  onClick={()=>this.SGaddItem(key)} style={{ 'font-size':'10px'}}/>
                          <Button icon='minus' label='減一個品項'  size='mini' color='blue'  onClick={()=>this.SGcutItem(key)} style={{ 'font-size':'10px'}}/>
                    </Form.Field> 
                    <Form.Field  width='3' inline >
                          
                          <Input label={{content: '重量-KGs', color:'red', size:'mini' }}  size='mini' placeholder='重量' value={box['weight']}  name="weight" onChange={(e)=>this.SGChangeBoxSpec(key,e)}   style={{'font-size':'10px'}}/>
                    </Form.Field>
                    

                    <Form.Field  width='3' inline>
                          <label style={{'font-size':'10px'}}>申報總值-NT$</label>
                          <Label color='green' style={{'font-size':'15px',}}>{box['amount']}</Label> 
                    </Form.Field>
                </Form.Group>
              
                {itemInput}
                
                <Divider style={{ 'margin-top':'30px'}}/>
              </div>
                
                
                
            
            ) 
                    
        })
      

      }
      

    }else{
      //新加坡以外的國家都跑這個
      for(let i=0;i<this.state.itemNumber;i++){
        
        let number=i+1
        orderInput.push(
                      <Form.Group key={i}  style={{ height: '2.2em',}} >
                            <label style={{'font-size':'12px' }}>{number}</label>
                            <Form.Field>
                                <label style={{'font-size':'12px' }}>品名</label>
                                <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold'}} placeholder='請輸入品名' value={this.state['itemName'+number]} name={'itemName'+number} onChange={this.handleInputChange}/>
                            </Form.Field>
                          
                            <Form.Field>
                                <label style={{'font-size':'12px' }}>數量</label>
                                <input style={{'font-size':'14px', height: '2em',width:80 ,'font-weight':'bold'}} placeholder='件數' value={this.state['itemNumber'+number]} name={'itemNumber'+number} onChange={this.handleInputChange}/>
                            </Form.Field>

                            <Form.Field>
                                <label style={{'font-size':'12px' }}>報關總值$NT</label>
                                <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold' }} placeholder='台幣金額' value={this.state['itemAmount'+number]} name={'itemAmount'+number} onChange={this.handleItemAmountChange}/>
                            </Form.Field>
                            
                     </Form.Group>
        )    　
     }

    }

    //這邊要設定箱數的顯示模式
    let totalPcNumber=[]
    if (this.state.deliveryNation==='SG'){
      if (this.state.boxNumber==='' || this.state.boxNumber===1){
        totalPcNumber.push(
          <Form.Field>
              <label style={{'font-size':'12px','font-weight':'bolder'}}>箱數</label>
              <input placeholder='件數' value={this.state.boxNumber}  name="boxNumber" onChange={this.boxNumberChange} style={{ padding: '0.3em'}}/>
          </Form.Field>
  
        )

      }else{
        totalPcNumber.push(
          <Form.Field>
              <label style={{'font-size':'12px','font-weight':'bolder'}}>箱數</label>
              <Label color='green' style={{'font-size':'15px',}}>{this.state.boxNumber}</Label>
          </Form.Field>
        )

      }
      

    }else{
      totalPcNumber.push(
        <Form.Field>
            <label style={{'font-size':'12px','font-weight':'bolder'}}>箱數</label>
            <input placeholder='件數' value={this.state.boxNumber}  name="boxNumber" onChange={this.boxNumberChange} style={{ padding: '0.3em'}}/>
        </Form.Field>

      )

    }

   
   return (

 
       
             
       <div style={{ 'padding-top': '0.5em','padding-left': '2em','padding-right': '2em','padding-bottom': '2em',width:'80%',margin: 'auto'}}>
         <Grid  divided='vertically' centered >
             <Modal  open={this.state.loading}>
                <Loader content='處理中'  size='huge' />
            </Modal>
             <Grid.Row columns={1}>  
                 <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                 
                   <Segment raised>  
                         <Label as='a' color='red' ribbon>
                         請輸入寄件資料
                         </Label>
                         
                         <Form style={{'font-size':'16px'}}>
                             <Form.Group widths='equal' id='shipperForm' style={{ height: '2em'}}> 
                                 <Form.Field>
                                     <label style={{'font-size':'12px'}}>國家別</label>
                                    
                                     <Form.Group style={{ 'padding-right': '0em'}} >
                                     <Form.Field
                                         control={Radio}
                                         label='馬來西亞'
                                         value='MY'
                                         checked={this.state.deliveryNation=== 'MY'}
                                         onChange={this.changeDeliveryNation}
                                         style={{'font-size':'18px','font-weight':'bolder'}}
                                     />
                                     
                                     <Form.Field
                                         control={Radio}
                                         label='新加坡'
                                         value='SG'
                                         checked={this.state.deliveryNation=== 'SG'}
                                         onChange={this.changeDeliveryNation}
                                         style={{'font-size':'18px','font-weight':'bolder'}}
                                     />
                                     
                                     
                                     {/* <Form.Field
                                         control={Radio}
                                         label='香港'
                                         value='HK'
                                         checked={this.state.deliveryNation=== 'HK'}
                                         onChange={this.changeDeliveryNation}
                                         style={{'font-size':'18px'}}
                                     />
                                     
                                     
                                     <Form.Field
                                         control={Radio}
                                         label='澳門'
                                         value='MO'
                                         checked={this.state.deliveryNation=== 'MO'}
                                         onChange={this.changeDeliveryNation}
                                         style={{'font-size':'18px'}}
                                     /> */}
                                     
                                     <Form.Field
                                         control={Radio}
                                         label='日本'
                                         value='JP'
                                         checked={this.state.deliveryNation=== 'JP'}
                                         onChange={this.changeDeliveryNation}
                                         style={{'font-size':'18px','font-weight':'bolder'}}
                                     />
                                     
                                     </Form.Group>
                                 </Form.Field> 
                             </Form.Group>
                             <Form.Group widths='equal'  style={{ height: '2.5em'}}> 
                                 <Form.Field>
                                     <label style={{'font-size':'12px','font-weight':'bolder'}}>收件人電話(請輸入單一電話，勿含特殊符號)</label>
                                     <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}}  placeholder='電話' value={this.state.receiverPhoneNumber} name="receiverPhoneNumber" onChange={this.handlePhoneNumberChange} />
                                 </Form.Field> 
                             </Form.Group>

                             <Form.Group widths='equal'  style={{ height: '2.5em'}}> 
                                 <Form.Field>
                                     <label style={{'font-size':'12px','font-weight':'bolder'}}>收件人姓名</label>
                                     <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}} placeholder='姓名' value={this.state.contact}  name="contact" onChange={this.handleInputChange} />
                                 </Form.Field> 
                                 {/* 日本功能*/}
                                 {/* {this.state.deliveryNation==='JP'?
                                    <Form.Field>
                                    <label style={{'font-size':'12px'}}>收件人英文姓名</label>
                                    <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}} placeholder='英文姓名' value={this.state.contactENG}  name="contactENG" onChange={this.handleInputChange} />
                                    </Form.Field>
                                    :
                                    "" 
                                  }      */}
                             </Form.Group>

                             {/* 日本功能*/}
                             {this.state.deliveryNation==='JP'?
                                   <Form.Group  style={{ height: '2.5em'}}>
                                      <Form.Field width={10}>
                                        <label style={{'font-size':'12px'}}>收件人地址</label>
                                        <input style={{'font-size':'14px', height: '2em','font-weight':'bold',padding: '0.3em' }} placeholder='地址' value={this.state.address} name="address" onChange={this.handleInputChange} />
                                      </Form.Field>
                                      {/* <Form.Field  width={6}>
                                        <label style={{'font-size':'12px'}}>門牌、室號(或建築物名稱)</label>
                                        <input style={{'font-size':'14px', height: '2em','font-weight':'bold' ,padding: '0.3em'}} placeholder='門牌號' value={this.state.doorNumber} name="doorNumber" onChange={this.handleInputChange} />                          
                                      </Form.Field> */}
                                      <Form.Field  width={6}>
                                        <label style={{'font-size':'12px'}}>代收款金額</label>
                                        <input style={{'font-size':'14px', height: '2em','font-weight':'bold' ,padding: '0.3em'}} placeholder='金額' value={this.state.payment} name="payment" onChange={this.handleInputChange} />                          
                                      </Form.Field>
                                    </Form.Group>
                                    :
                      
                                      <Form.Group widths='equal' style={{ height: '2.5em' }}>
                                        <Form.Field>
                                          <label style={{ 'font-size': '12px' }}>收件人地址</label>
                                          <input style={{ 'font-size': '14px', height: '2em', 'font-weight': 'bold' ,padding: '0.3em'}} placeholder='地址' value={this.state.address} name="address" onChange={this.handleInputChange} />
                                        </Form.Field>
                                      </Form.Group>
                      
                              }
                            
                             
                                 
                            {this.state.deliveryNation==='SG'?
                                    <Form.Group widths='equal' >
                                        <Form.Field>
                                            <label style={{'font-size':'12px'}}>郵遞區號(必填)</label>
                                            <input  placeholder='郵遞區號' value={this.state.zipCode} name="zipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        {this.state.poNumber!==''?
                                            <Form.Field>
                                            <label style={{'font-size':'12px'}}>快遞單號</label>
                                            <Label color='green' style={{'font-size':'15px',}}>{this.state.poNumber}</Label> 
                                            </Form.Field>
                                        :
                                            <div/>

                                        }
                                        {totalPcNumber}
                                    
                                        
                                    </Form.Group>
                                    :
                                    <Form.Group widths='equal' >
                                        <Form.Field>
                                            <label style={{'font-size':'12px'}}>郵遞區號(必填)</label>
                                            <input  placeholder='郵遞區號' value={this.state.zipCode} name="zipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        {this.state.poNumber!==''?
                                            <Form.Field>
                                            <label style={{'font-size':'12px'}}>快遞單號</label>
                                            <Label color='green' style={{'font-size':'15px',}}>{this.state.poNumber}</Label> 
                                            </Form.Field>
                                        :
                                            <div/>

                                        }
                                        
                                        {totalPcNumber}
                                    
                                        <Form.Field>
                                          <label style={{'font-size':'12px'}}>重量-KGs</label>
                                          <input placeholder='重量' value={this.state.weight}  name="weight" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>

                                        <Form.Field>
                                          <label style={{'font-size':'12px'}}>申報價值-NT$</label>
                                          <Label color='green' style={{'font-size':'15px',}}>{this.state.amount}</Label> 
                                        </Form.Field>
                                    </Form.Group>
                                    
                                 }


                             
                                 
                             
                             {this.state.deliveryNation==='SG'?
                                 <div>
                                      <Form.Group>
                                          <Form.Field>
                                            <Button icon='plus' label='多加一箱'  size='small' color='red' onClick={this.addBox} style={{ 'font-size':'15px'}}/>
                                          </Form.Field>
                                      </Form.Group>
                                      <Divider />
                                 </div>
                                 
                                :
                                <div>
                                </div>
                                 
                             }

                             {this.state.deliveryNation==='SG'?
                               
                               <div>
                                  {orderInput}
                               </div>
                               
                            
                               :
                                <div>
                                    <Form.Group>
                                          <Button icon='plus' label='加一個品項'  size='mini' color='orange' onClick={this.addItem} style={{ 'font-size':'10px'}}/>
                                          <Button icon='minus' label='減一個品項'  size='mini' color='blue' onClick={this.cutItem} style={{ 'font-size':'10px'}}/>
                                    </Form.Group>
                                    
                                    {orderInput}
                                </div>
                             }
                             
                             <Form.Group style={{ 'padding-top':'1em'}}>
                                   <Button icon='print' label='印製標籤' size='mini' color='red' onClick={this.print} style={{ 'font-size':'10px'}}/> 
                             </Form.Group>
                             {this.state.printErrorMessage?
                               <Label style={{ width: 130,'padding-right':'1em'}}> {this.state.printErrorMessage}</Label>
                               :
                               <div></div>
                             }
                             
                         </Form>
                   
                   </Segment>
                
             </Grid.Column>
             </Grid.Row>

             <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                             已入單未出貨訂單
                             </Label>
                             
                             <Table celled >
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em', width:250}}>收件人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件連絡電話</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>印製標籤</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>刪除</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.dataOnScreen}

                                      <Confirm
                                      open={this.state.deleteFinish}
                                      content='確定要刪除訂單??'
                                      onCancel={this.deleteClose}
                                      onConfirm={this.deleteRecord}
                                      cancelButton='取消'
                                      confirmButton='確定'
                                    />
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
                 </Grid.Row>
             
         </Grid>
       </div>

    




   );
  
}


}

export default App;
