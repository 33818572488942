import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin} from './helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import {ref,firebase,firebaseAuth} from '../firebase/firebaseConfig';
import {Button,Input, Header,Table,Modal,Loader} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'80036',
      // 'customerNumber':'test',
      'SGPO':[],
      'finalDeliveryNumber':'',
      'cumulatedDeliveryNumber':'',
      'todayTitle':'',
      'nation':'',
      'okToPrint':true,
      'file':''
    };
    this.UniversalImportExcel=this.UniversalImportExcel.bind(this);
    this.handleUniversalLablePrint=this.handleUniversalLablePrint.bind(this);
  }

  componentDidMount (){
    //var userid = firebaseAuth.currentUser.uid;
    let today=todayTitle()[0];
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];
    this.setState({
      'todayTitle':today,
      'year':year,
      'month':month,
      'day':day
    })
    // this.setState({
    //   'todayTitle':20210906,
    //   'year':2021,
    //   'month':'09',
    //   'day':'06'
    // })

   
  }


  handleUniversalLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改 
    //這是通用universal的版本，所以印製張數看表格
    /*
    要加印
    [{ text: 'A/C NO:'},
    {text: 'N6900038',
      colSpan: 5},
    */
   
    this.setState({
        loading:true,
    })

    let goodData=this.state['MIXPO'];
    //let Nation='MY';
    //let Currency='MYR';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.印製張數);
      var dataURL=textToBase64Barcode(po.快遞單號);

      let currency=''
      if(po.國別==='HK'){
            currency='HKD'
      }else if(po.國別==='MY'){
            currency='MYR'
      }else if(po.國別==='SG'){
            currency='SGD$'
      }else if(po.國別==='MO'){
            currency='NTD$'
      }else if(po.國別==='JP'){
            currency='NTD$'
      }
  
      
        //馬來西亞的標籤數需求就是總箱數再加1張，就這麼簡單
          for(var i=0;i<LabelNumber;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i===LabelNumber-1){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 3,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text:po.快遞單號,
                      colSpan: 3, 
                      style: 'head2', 
                      width:140, 
                      height:10,
                      alignment: 'center', },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    // [
                    // //   {text: 'Declared Value:'},
                    // // {text: currency+' '+po['報關總值'],colSpan: 5},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {}
                    // ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    //{text: po['內容物'],colSpan: 5},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text:po.快遞單號,
                      colSpan: 3, 
                      style: 'head2', 
                      width:140, 
                      height:10,
                      alignment: 'center', },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    // [
                    //   //   {text: 'Declared Value:'},
                    //   // {text: currency+' '+po['報關總值'],colSpan: 5},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {}
                    // ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    //{text: po['內容物'],colSpan: 5},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 8
        } ,
        styles: {
          head: {      
            fontSize: 66,
            bold: true
          },
          head2: {                
            fontSize: 16,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 326, height: 652},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+this.state.nation+this.state.todayTitle+'.pdf';
      
      
           
      var connectedRef = firebase.database().ref(".info/connected");
      connectedRef.once("value", (snap) => {
        if (snap.val() === true) {
          pdfmake.createPdf(docDefinition).download(labeltitle, ()=>{ this.setState({ loading:false})  });
           
        } else {
          this.setState({ 
            printErrorMessage:'請檢查網路狀態，並重新上傳資料!',
            loading:false
          })
        }
      });

      

      

      
      

  }
  


  UniversalImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='MIX'
        let goodData={};

        
        let length=data.length
        let index=0
        data.forEach((element)=> {                      
                  //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                  let number=element.跨境通提單號碼;
                  let date = new Date();
                                
                  goodData[number]={};
                  goodData[number].印製張數=element.印製張數;
                  goodData[number].快遞單號=element.跨境通提單號碼;;
                  
                  if(element['訂單單號']){
                    goodData[number].訂單單號=element['訂單單號'];
                  }else{
                    goodData[number].訂單單號='';
                  }

                  if(element['AC-NO']){
                    goodData[number].acno=element['AC-NO'];
                  }else{
                    goodData[number].acno='';
                  }

                  if(element['AC-NO'] && element.國別==='SG'){
                    goodData[number].收件人郵遞區號=element['AC-NO']
                  }else{
                    goodData[number].收件人郵遞區號=''
                  }
                  

                  

                  //date一定要做這個處理，要不然進不了realtime database
                  goodData[number].日期=date.toDateString();                            
                  goodData[number].寄件人姓名=element.寄件人姓名;

                  if(element['寄件人連絡電話']){
                    goodData[number].寄件人連絡電話=element['寄件人連絡電話'];
                  }else{
                    goodData[number].寄件人連絡電話='';
                  }

                  if(element['寄件人地址']){
                    goodData[number].寄件地址=element['寄件人地址'];
                  }else{
                    goodData[number].寄件地址='';
                  }
                               
                  //goodData[number].寄件地址="";
                  //goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                  goodData[number].收件人姓名=element.收件人姓名;
                  
                  goodData[number].收件地址=element.收件人地址;
                  goodData[number].收件連絡電話=element.收件人連絡電話;

                  goodData[number].國別=element.國別;

                  goodData[number].總箱數=element.件數;

                  goodData[number]['總重量-公斤']=element.重量;
                  
                  goodData[number]['報關總值']=element.總值;

                  if(element.國別==='HK'){
                        goodData[number]['報關總值']=Number((element.總值/4).toFixed(1));
                        // nation='HK'
                        this.setState({ 
                          nation:'HK'
                        })
                  }else if(element.國別==='MY'){
                        goodData[number]['報關總值']=Number((element.總值/7.2).toFixed(1))
                        // nation='MY'
                        this.setState({ 
                          nation:'MY'
                        })
                  }else if(element.國別==='SG'){
                        goodData[number]['報關總值']=Number((element.總值/22).toFixed(1))
                        // nation='SG'
                        this.setState({ 
                          nation:'SG'
                        })
                  }else if(element.國別==='MO'){
                        goodData[number]['報關總值']=element.總值
                        // nation='MO'
                        this.setState({ 
                          nation:'MO'
                        })
                  }else if(element.國別==='JP'){
                        goodData[number]['報關總值']=element.總值
                        // nation='JP'
                        this.setState({ 
                          nation:'JP'
                        })
                  }

                  
                  if (element.國別==='SG'){
                        var Array = element.內容物.split(/\*/);
                        var name = element.內容物.split(/\s*\*\d*/);
                        //算一下一共幾件物品
                        var itemNumber=0
                        //要計算平均單價，所以要計算總數才可以平均
                        let totalNumber=0
                        let content=''

                        //先把第一輪的分類做出來
                        let itemName=[]
                        let itemUnits=[]
                        for (let i = 0; i < Array.length-1; i++) {
                            
                            let item =name[i]
                            if(item.indexOf("(NTD")!==-1){
                                let startPoint=item.indexOf(")")+1
                                item=item.slice(startPoint)
                            }
                            let itemNum = Array[i+1].replace(name[i+1],'')
                            itemName.push(item.trim())
                            itemUnits.push(itemNum)
                            
                        }
                        
                        //把+號拆分掉
                        let itemNameFinal=[]
                        let itemUnitsFinal=[]
                        for (let h = 0; h < itemName.length; h++) {
                              let targetName =itemName[h]
                              let targetUnit=itemUnits[h]
                              let unitName=''

                              if(targetName.includes('+')){
                                 unitName=targetName.split('+')
                              }else{
                                 unitName=[targetName]
                              }
                              
                              for (let j = 0; j < unitName.length; j++) {
                                itemNameFinal.push(unitName[j])
                                itemUnitsFinal.push(targetUnit)
                              }
                        }

                        for (let z = 0; z < itemNameFinal.length; z++) {
                          let num=z+1
                          goodData[number]['itemName'+num]=itemNameFinal[z]
                          goodData[number]['itemNumber'+num]=itemUnitsFinal[z]
                          
                          //我要算一下有幾個品項
                          itemNumber=num
                          totalNumber=totalNumber+Number(itemUnitsFinal[z])
                          content=content+itemNameFinal[z]+'*'+itemUnitsFinal[z]+' '

                        }



                        //為了符合Q10不可以零單價的狀況，所以全部採平均值
                        let price= ((element.總值)/totalNumber).toFixed()
                        for (let i = 0; i < itemNameFinal.length; i++) {
                            let num=i+1
                            goodData[number]['itemAmount'+num]=price*Number(goodData[number]['itemNumber'+num])
                        }
                        goodData[number].內容物=content
                        goodData[number]['itemNumber']=itemNumber

                        

                  }else{
                        goodData[number].內容物=element.內容物;
                  
                  }
                  

                  //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                  
                  savePO(this.state.customerNumber,this.state.year,this.state.month,this.state.day,goodData[number]);
                  index=index+1
                  //console.log(goodData[number])
      });
          
          // var connectedRef = firebase.database().ref(".info/connected");
          // 資料都上傳以後，調出來，對一下筆數對不對
          // 如果筆數很少的時候，代表不是大規模上傳，只是改幾單，那不驗了，就直接上
          if(length<20){
                this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success', 'okToPrint':false, 'file':Math.random()});
          }else{
                var userid = firebaseAuth.currentUser.uid;
                ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.nation+'/'+userid).on('value', snap => {
                  if (snap.val() !==null) {
                    //我要確認筆數正確，才要出標
                    let orders=snap.val()
                    let ordersLength=Object.keys(orders).length
                    if(ordersLength===index){
                          this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success', 'okToPrint':false});
                    }else{
                          this.setState({ 
                            printErrorMessage:'資料上傳不完全，請再上傳一次',
                            file:Math.random()
                          })
                    }
                    console.log(index, ordersLength)
                    
                  } 
                });
          }
          
                      
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.year,this.state.month,this.state.day);

      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }
  

 

  

  


  render() {
    return (



      <div style={{ padding: '5em 5em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
        <Modal  open={this.state.loading}>
                                 <Loader content='處理中'  size='huge' />
        </Modal>
        <Header as='h1'>您好，樂利數位科技。</Header>
        <Table celled padded textAlign='center' style={{ padding: '5em 5em','background-color': 'rgb(244, 173, 66)',fontSize:20 }}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>寄送國別</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>上傳資料</Table.HeaderCell>
                <Table.HeaderCell>下載標籤</Table.HeaderCell>
                
            </Table.Row>
            </Table.Header>
        
            <Table.Body>
            

            <Table.Row style={{'background-color': 'rgb(174, 198, 45)' }}>
                <Table.Cell><h1>萬用格式</h1></Table.Cell>
                <Table.Cell>
                       
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳萬用格式訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' key={this.state.file || '' }   onChange={this.UniversalImportExcel} />
                    {this.state.MIXUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                    <Button size='large' disabled={this.state.okToPrint} color='orange' onClick={this.handleUniversalLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                    {this.state.printErrorMessage?<h1>{this.state.printErrorMessage}</h1>:''}
                </Table.Cell>
            </Table.Row>

           
            
            </Table.Body>
        </Table>

      </div>




    );
  }
}

export default App;
